<template>
  <div>
    <div v-if="!hasBackofficeAccess && isInProcess && !isDisabled && !hasReturnPreRequest && isMounted && showTechnologyTab" class="vx-col w-full mb-2 flex" id="account-manage-buttons">
      <!-- <vx-card style="background-color: #28DE18 !important;">
        <h4 class="mb-2 white">¿Terminaste la solicitud?</h4>
        <p class="white">No olvides dar clic en <strong>"Enviar solicitud completa"</strong> al finalizar con la solicitud.</p>
        <p class="white">Recuerda que en caso de existir cambios, deberás dar clic nuevamente.</p>
        <vs-button class="mr-4 mt-4" @click="requestVerification" color="dark">Enviar solicitud completa</vs-button>
      </vx-card> -->
    </div>
    <div v-else-if="!hasBackofficeAccess && isMounted && hasReturnPreRequest" class="vx-col w-full mb-2 flex" id="account-manage-buttons">
      <!-- <vs-button class="mr-4" @click="preRequestVerification">Enviar pre-solicitud completa</vs-button> -->
    </div>
    <div class="mb-base" v-if="!showTechnologyTab">
      <vs-alert color="warning" active="true" style="height:auto">
        Tu perfil esta en <strong>proceso de verificación</strong>. Puedes crear una Pre-Solicitud para que RedGirasol evalúe su viabilidad crediticia. Solo considera que para concluir el proceso de evaluación de la Solicitud deberás tener tu perfil como Proveedor ya verificado por parte de RedGirasol.
        <br>
        <vs-button v-if="!supplierIsVerified" class="mt-2 mb-2" color="warning" @click="goToOnboarding()">Completar perfil</vs-button>
      </vs-alert>
    </div>
    <!-- SECCIONES -->
    <vs-tabs v-if="isMounted" :position="isSmallerScreen ? 'top' : 'left'" v-model="activeTab" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
      <vs-tab :color="classForTab(group)" :disabled="group.disabled" :label="titleForTab(group)" icon-pack="feather" :icon="iconForTab(group)" v-for="(group, i) in getSectionGroups()" :key="i">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card no-shadow>

            <vs-alert v-if="group.failed > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-0 mb-3" color="danger">
                <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                  <span v-for="(miss, i) in group.missing" :key="i">
                    <strong>- {{miss.n}}: </strong> {{miss.e}}<br>
                  </span>
                </span>
            </vs-alert>
            
            <template v-for="section in getSectionsForGroup(group)">
              <vx-card v-if="section.data.id != 14 && section.data.id != 26" :key="section.data.id" class="mb-5">
                <h3>{{ get_section_name(section) }}</h3>
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>
                <vs-divider></vs-divider>

                <!-- Verificar OS -->
                  <vs-alert v-if="isObligatedSection(section.data.id) && obligated_status == 'rejected'" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-0 mb-6" color="danger">
                    <span class="font-regular"><strong>Obligado Solidario Rechazado</strong><br>
                      <span>
                        El obligado solidario fue rechazado, para continuar con el proceso será necesario que actualices la información con un nuevo obligado solidario.
                      </span>
                    </span>
                  </vs-alert>

                  <vs-alert v-if="isObligatedSection(section.data.id) && obligated_status == 'approved'" icon-pack="feather" style="height:auto" icon="icon-info" class="mt-0 mb-6" color="success">
                    <span class="font-regular"><strong>Obligado Solidario Verificado</strong><br>
                      <span>
                        El obligado solidario se verificó exitosamente.
                      </span>
                      <div v-if="hasBackofficeAccess" class="flex flex-wrap items-center justify-end">
                        <vs-button size="small" color="primary" class="ml-2 mt-2" @click="requestToUpdateObligatedStatus(2)">Desverificar</vs-button>
                      </div>
                    </span>
                  </vs-alert>

                  <div class="vx-row mb-6" v-if="showGuaranteeVerifyButtons(section)">
                    <div class="vx-col w-full">
                      <div class="flex flex-wrap items-center justify-end">
                        <vs-button color="success" class="ml-auto mt-2" @click="requestToUpdateObligatedStatus(1)">Verificar</vs-button>
                        <vs-button color="danger" class="ml-2 mt-2" @click="requestToUpdateObligatedStatus()">Rechazar</vs-button>
                      </div>
                    </div>
                  </div>
                  <!-- Verificar OS -->

                <div class="vx-row">

                  <template v-for="f in section.data.fields">

                    <!-- Campos automaticos -->
                    <form-field
                      :ref="f.external_computed ? `computed_${f.fname}` : `section${section.data.id}`"
                      v-if="isAutoFormField(f) && showFinanceFields(f.db_field, section.data.id)"
                      :key="f.id"
                      :f="f"
                      :keepDisabled="isDisabled"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :hasBackofficeAccess="hasBackofficeAccess"
                      :blockedByDocument="f.blockedByDocument"
                      :inputClassLarge="true"
                      :countries-list="collections.countries"
                      :evaluator="evaluateCondition" />

                    <!-- Campos y controles especiales -->

                    <technology-selector-control
                      :key="f.id"
                      :f="f"
                      :project_id="id"
                      :isDisabled="isDisabled || hasBackofficeAccess"
                      :familyGroups="collections.family_groups"
                      v-else-if="f.fname == 'technologies_list_selector'" />

                    <applicant-references-control
                      :key="f.id"
                      :applicant_id="currentClientId"
                      :projectId="currentProjectId"
                      :isDisabled="isDisabled || hasBackofficeAccess"
                      @updated="runSilentValidation"
                      v-else-if="f.fname == 'applicant_references'" />

                    <address-divider
                      :key="f.id"
                      v-else-if="f.fname == 'address_divider' && client_should_be_working_and_not_the_same_address" />

                    <income-sources-selector-control
                      :key="f.id"
                      :f="f"
                      :project="base"
                      :p_type="person_type"
                      :role="get_income_source_role(activeTab)"
                      @updated="runSilentValidation"
                      v-else-if="f.fname == 'income_sources_list_selector'" />

                    <personal-profile-tins-control
                      :key="f.id"
                      :f="f"
                      :personal_profile_id="business.owner_personal_profile.id"
                      ref="personal_profile_tins_component"
                      v-else-if="f.fname == 'owner_tins' && should_fill_owner_pf_tins"
                      :disabled="f.blockedByDocument || isDisabled  || business.owner_personal_profile.id == null"
                      :countries="collections.countries" />

                    <business-profile-tins-control
                      :key="f.id"
                      :f="f"
                      :business_profile_id="business.owner_business_profile.id"
                      ref="business_profile_tins_component"
                      v-else-if="f.fname == 'business_tins' && should_fill_business_tins"
                      :disabled="f.blockedByDocument || isDisabled || business.owner_business_profile.id == null"
                      :countries="collections.countries" />

                    <div class="vx-col w-full mb-1 mt-2" :key="f.id" v-else-if="f.fname == 'os_type_selector'">
                      <v-select
                        name="family_group_id"
                        label="text"
                        class="vs-custom w-full mb-5"
                        v-model.lazy="guaranteeType"
                        :options="[{ text: 'Persona física', value: 1 }, { text: 'Persona moral', value: 2 }]"
                        :clearable="false"
                        :searchable="false"
                        placeholder="Seleccione una opción"
                        :disabled="isGuaranteeSaved">
                        <div slot="no-options">No hay coincidencias</div> 
                      </v-select>
                    </div>

                    <div class="vx-col w-full mb-1 mt-2" :key="f.id" v-else-if="f.fname == 'owner_type_selector'">
                      <v-select
                        name="family_group_id"
                        label="text"
                        class="vs-custom w-full mb-5"
                        v-model.lazy="ownerType"
                        :options="[{ text: 'Persona física', value: 1 }, { text: 'Persona moral', value: 2 }]"
                        :clearable="false"
                        :searchable="false"
                        placeholder="Seleccione una opción"
                        :disabled="isOwnerSaved">
                        <div slot="no-options">No hay coincidencias</div> 
                      </v-select>
                    </div>

                    <div class="vx-col w-full mb-1 mt-2" :key="f.id" v-else-if="f.fname == 'same_profile_as'">
                      <label for="" class="vs-input--label">En caso del Obligado Solidario ser la misma persona que otra relacionada con el proyecto, por favor selecciona una:</label>
                      <v-select
                        name="family_group_id"
                        label="text"
                        class="vs-custom w-full mb-5"
                        v-model.lazy="selectedRelatedProfile"
                        :options="relatedProfilesList"
                        :clearable="false"
                        :searchable="false"
                        placeholder="Seleccione una opción">
                        <div slot="no-options">No existen personas físicas relacionadas con el proyecto</div> 
                      </v-select>
                    </div>

                    <div class="vx-col w-full mb-1 mt-2" :key="f.id" v-else-if="f.fname == 'is_a_different_profile' && is_a_different_profile">
                      {{getIsDifferentProfileText}}
                    </div>

                  </template>

                </div>

                <!-- Save Button -->
                <div class="vx-row" v-if="isSaveButtonShown(section)">
                  <div class="vx-col w-full">
                    <div class="flex flex-wrap items-center justify-end">
                      <!-- <vs-button v-if="isInProcess && supplierIsVerified" class="ml-4 mt-2" @click="requestVerification" color="dark">Enviar solicitud completa</vs-button> -->
                      <vs-button class="ml-3 mt-2" @click="saveGroupChanges(section)">Guardar cambios</vs-button>
                    </div>
                  </div>
                </div>

                <template v-if="section.data.documentFields.length > 0">
                  <vs-divider></vs-divider>
                  <h6 class="mb-3">Documentos</h6>
                  <div class="vx-row">
                    <document-upload
                      v-for="df in section.data.documentFields"
                      :key="df.id"
                      :target_id="df.target_id"
                      :f="df.formField"
                      :doc="df.documentSet"
                      :inputClassLarge="!isBackoffice"
                      :disabled="isDisabled"
                      :evaluator="evaluateCondition"
                      :loadedState="df.loadedState"
                      :stickToLoadedState="true"
                      :from_project_id="currentProjectId"
                      :editableByBackoffice="true"
                      @on-success="onDocumentUploadSuccess"
                    />
                  </div>
                </template>

              </vx-card>
              <vx-card v-else-if="section.data.id == 14" :key="section.data.id" class="mb-5">
                <h3>{{ get_section_name(section) }}</h3>
                <p class="mb-4">Datos generales de tu cliente. </p>
                <vs-divider></vs-divider>
                <div class="vx-row">
                  <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
                    <div class="applicant-card mb-5">
                      <div class="flex">
                        <feather-icon
                          icon="UserIcon"
                          svgClasses="mr-5"
                          class="primary"
                        />
                        <div>
                          <p>Nombre del cliente: <span>{{ `${personal.first_name} ${personal.second_name||""} ${personal.last_name_1} ${personal.last_name_2||""}` }}</span></p>
                          <p>Email: <span>{{ base.client.user.email }}</span></p>
                          <p>Tel: <span>{{ personal.country_calling_code }} {{ phoneFormat(personal.new_phone) }}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vx-card>
              <vx-card v-else-if="section.data.id == 26" :key="section.data.id" class="mb-5">
                <h3>{{ get_section_name(section) }}</h3>
                <p class="mb-4">Datos generales de tu cliente. </p>
                <vs-divider></vs-divider>
                <div class="vx-row">
                  <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
                    <div class="applicant-card mb-5">
                      <div class="flex">
                        <feather-icon
                          icon="BriefcaseIcon"
                          svgClasses="mr-5"
                          class="primary"
                        />
                        <div>
                          <p>Persona Moral</p>
                          <p>Nombre del cliente: <span>{{ `${business.name}` }}</span></p>
                          <p>RFC: <span>{{ `${business.rfc}` }}</span></p>
                          <p>Tel: <span>{{ business.country_calling_code }} {{ phoneFormat(business.new_phone) }}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
                    <div class="applicant-card mb-5">
                      <div class="flex">
                        <feather-icon
                          icon="UserIcon"
                          svgClasses="mr-5"
                          class="primary"
                        />
                        <div>
                          <p>Representante legal</p>
                          <p>Nombre del cliente: <span>{{ `${businessOwner.first_name} ${businessOwner.second_name||""} ${businessOwner.last_name_1} ${businessOwner.last_name_2||""}` }}</span></p>
                          <p>Email: <span>{{ base.client.user.email }}</span></p>
                          <p>Tel: <span>{{ businessOwner.country_calling_code }} {{ phoneFormat(businessOwner.new_phone) }}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vx-card>
            </template>

          </vx-card>
        </div>
      </vs-tab>

    </vs-tabs>

    <load-error v-if="failed" />

  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import docsHelper from '@components/mixins/docsHelper';
import formatHelper from '@components/mixins/formatHelper';
import FormField from '@components/forms/FormField.vue'
import TechnologySelectorControl from '@technologies/TechnologySelectorControl'
import ApplicantReferencesControl from '@applicant/ApplicantReferencesControl'
import IncomeSourcesSelectorControl from '@incomeSources/IncomeSourcesSelectorControl'
import AddressDivider from '@components/forms/AddressDivider.vue'
import { Validator } from 'vee-validate'
import BusinessProfileTinsControl from '@supplier/BusinessProfileTinsControl.vue';
import PersonalProfileTinsControl from '@supplier/PersonalProfileTinsControl.vue';

const pfTabsDefs = [
  { slug: "client", title: "Información del solicitante", icon: "icon-user", sections:[14] },
  // { slug: "address", title: "Domicilio del solicitante", icon: "icon-map-pin", sections:[32] },
  // { slug: "references", title: "Referencias del solicitante", icon: "icon-tag", sections:[34] },
  // { slug: "bank", title: "Información bancaria", icon: "icon-credit-card", sections:[35] },
  // { slug: "income_sources", title: "Ingresos del solicitante", icon: "icon-dollar-sign", sections:[131] },
  // { slug: "obligated", title: "Obligado solidario", icon: "icon-user-plus", sections:[137, 19, 20, 21, 122, 123, 124, 125] },
  // { slug: "obligated_income_sources", title: "Ingresos del obligado solidario", icon: "icon-dollar-sign", sections:[131] },
  { slug: "technologies", title: "Tecnologías a financiar", icon: "icon-zap", sections:[31,36] },
  { slug: "finance", title: "Financiamiento", icon: "icon-file-text", sections:[47] },
];

const pmTabsDefs = [
  { slug: "client", title: "Información del solicitante", icon: "icon-user", sections:[26] },
  // { slug: "address", title: "Domicilio del solicitante", icon: "icon-map-pin", sections:[16] },
  // { slug: "references", title: "Referencias del solicitante", icon: "icon-tag", sections:[34] },
  // { slug: "bank", title: "Información bancaria", icon: "icon-credit-card", sections:[94] },
  // { slug: "pm_income_sources", title: "Ingresos del solicitante", icon: "icon-dollar-sign", sections:[131] },
  // { slug: "representative", title: "Representante legal", icon: "icon-briefcase", sections: [
  //   // when aplicant is PM, business.legal_representative_is_owner = 1 & legal rep is PF
  //   27, 29, 52, 30
  // ]},
  // { slug: "representative_income_sources", title: "Ingresos del representante", icon: "icon-dollar-sign", sections:[131] },
  // { slug: "owner", title: "Dueño de la empresa", icon: "icon-briefcase", sections: [
  //   // owner type select
  //   142,
  //   // when aplicant is PM, business.legal_representative_is_owner = 0 & owner is PF
  //   143, 144, 145, 146,
  //   // when aplicant is PM, business.legal_representative_is_owner = 0 & owner is PM
  //   147, 148, 149, 150, 151
  // ]},
  // { slug: "owner_income_sources", title: "Ingresos del dueño de la empresa", icon: "icon-dollar-sign", sections:[131] },
  // { slug: "obligated", title: "Obligado solidario", icon: "icon-user-plus", sections:[137, 19, 20, 21, 122, 123, 124, 125, 141] },
  // { slug: "obligated_income_sources", title: "Ingresos del obligado solidario", icon: "icon-dollar-sign", sections:[131] },
  { slug: "technologies", title: "Tecnologías a financiar", icon: "icon-zap", sections:[31,36] },
  { slug: "finance", title: "Financiamiento", icon: "icon-file-text", sections:[47] },
];

const sectionsToContainers = [
	{
		id: 13, containers: [{ db: 'bank_accounts', path: 'user.business.bank_accounts', arrayDefs: { getFirst: true, onNull: 'newBankAccount' } }]
	},
	{
		id: 14, containers: [
			{ db: 'personal_profiles', path: 'client.user.personal' },
			{ db: 'phones', path: 'client.user.personal.phone' },
			{ db: 'users', path: 'client.user' },
			{ db: 'personal_credit_profiles', path: 'client.user.personal.credit_profile' },
			{ db: 'projects', path: 'base' },
			{ db: 'clients', path: 'client' }
		]
	},
	{
		id: 16, containers: [{ db: 'addresses', path: 'client.user.business.address' }]
	},
	{
		id: 19, containers: [
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
			{ db: 'personal_profiles', path: 'finance.guarantee.guarantee_profile.personal' },
			{ db: 'personal_credit_profiles', path: 'finance.guarantee.guarantee_profile.personal.credit_profile' },
      { db: 'phones', path: 'finance.guarantee.guarantee_profile.personal.phone' },
		]
	},
	{
		id: 20, containers: [{ db: 'addresses', path: 'finance.guarantee.guarantee_profile.personal.address' }]
	},
	{
		id: 21, containers: [
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
			{ db: 'personal_profiles', path: 'finance.guarantee.guarantee_profile.personal' },
			{ db: 'personal_credit_profiles', path: 'finance.guarantee.guarantee_profile.personal.credit_profile' },
      { db: 'phones', path: 'finance.guarantee.guarantee_profile.personal.phone' },
		]
	},
	{
		id: 26, containers: [
			{ db: 'business_profiles', path: 'client.user.business' },
			{ db: 'phones', path: 'client.user.business.phone' },
			{ db: 'projects', path: 'base' },
			{ db: 'clients', path: 'client' }
		]
	},
	{
		id: 27, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.personal' },
			{ db: 'phones', path: 'client.user.business.personal.phone' },
			{ db: 'users', path: 'client.user' }
		]
	},
	{
		id: 28, containers: [
			{ db: 'business_profiles', path: 'client.user.business' },
			{ db: 'business_credit_profiles', path: 'client.user.business.credit_profile' }
		]
	},
	{
		id: 29, containers: [{ db: 'business_profiles', path: 'client.user.business' }]
	},
	{
		id: 30, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.personal' },
			{ db: 'personal_credit_profiles', path: 'client.user.business.personal.credit_profile' },
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
			{ db: 'business_credit_profiles', path: 'client.user.business.credit_profile' },
			{ db: 'finance', path: 'finance' }
		]
	},
	{
		id: 31, containers: [{ db: 'projects', path: 'base' }, { db: 'clients', path: 'client' }]
	},
	{
		id: 32, containers: [{ db: 'addresses', path: 'client.user.personal.address' }]
	},
	{
		id: 33, containers: [
			{ db: 'personal_credit_profiles', path: 'client.user.personal.credit_profile' },
			{ db: 'personal_profiles', path: 'client.user.personal' },
			{ db: 'finance', path: 'finance' }
		]
	},
	{
		id: 34, containers: [{ db: 'project_related_profiles', path: 'related_profiles', arrayDefs: {} }]
	},
	{
		id: 35, containers: [
			{ db: 'bank_accounts', path: 'client.user.fiscal.bank_accounts', arrayDefs: { getFirst: true, onNull: 'newBankAccount' } },
			{ db: 'clients', path: 'client' }
		]
	},
	{
		id: 36, containers: []
	},
	{
		id: 44, containers: [{ db: 'client_contracts', path: 'contracts', arrayDefs: {} }]
	},
	{
		id: 47, containers: [
			{ db: 'projects', path: 'base' },
			{ db: 'project_finances', path: 'finance' },
			{ db: 'master_clients_amortizations', path: 'amortizations', arrayDefs: {} },
			{ db: 'personal_profiles', path: 'client.user.personal' },
		]
	},
	{
		id: 50, containers: [{ db: 'project_finances', path: 'finance' }]
	},
	{
		id: 51, containers: [{ db: 'project_finances', path: 'finance' }]
	},
	{
		id: 52, containers: [{ db: 'addresses', path: 'client.user.business.personal.address' }]
	},
	{
		id: 94, containers: [{ db: 'bank_accounts', path: 'client.user.fiscal.bank_accounts', arrayDefs: { getFirst: true, onNull: 'newBankAccount' } }]
	},
	{
		id: 97, containers: [{ db: 'addresses', path: 'client.user.business.guarantee_profile.personal.address', arrayDefs: { onNull: 'newGuaranteeProfileAddress' } }]
	},
	{
		id: 98, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.guarantee_profile.personal' },
			{ db: 'personal_credit_profiles', path: 'client.user.business.guarantee_profile.personal.credit_profile' },
			{ db: 'guarantee_profiles', path: 'client.user.business.guarantee_profile' },
		]
	},
	{
		id: 99, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.guarantee_profile.personal' },
			{ db: 'guarantee_profiles', path: 'client.user.business.guarantee_profile' },
		]
	},
	{
		id: 122, containers: [
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
			{ db: 'business_profiles', path: 'finance.guarantee.guarantee_profile.business' },
			{ db: 'business_credit_profiles', path: 'finance.guarantee.guarantee_profile.business.credit_profile' },
		]
	},
	{
		id: 123, containers: [
			{ db: 'personal_profiles', path: 'finance.guarantee.guarantee_profile.business.personal' },
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
      { db: 'phones', path: 'finance.guarantee.guarantee_profile.business.personal.phone' },
		]
	},
	{
		id: 124, containers: [
			{ db: 'addresses', path: 'finance.guarantee.guarantee_profile.business.address' }
		]
	},
	{
		id: 125, containers: [
			{ db: 'business_profiles', path: 'finance.guarantee.guarantee_profile.business' },
			{ db: 'business_credit_profiles', path: 'finance.guarantee.guarantee_profile.business.credit_profile' },
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
		]
	},
	{
		id: 131, containers: []
	},
	{
		id: 141, containers: [
			{ db: 'business_profiles', path: 'client.user.business' },
		]
	},
	{
		id: 143, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.owner_personal_profile' },
			{ db: 'guarantee_profiles', path: 'finance.owner.guarantee_profile' },
		]
	},
	{
		id: 144, containers: [{ db: 'business_profiles', path: 'client.user.business' }]
	},
	{
		id: 145, containers: [{ db: 'addresses', path: 'client.user.business.owner_personal_profile.address' }]
	},
	{
		id: 146, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.owner_personal_profile' },
			{ db: 'personal_credit_profiles', path: 'client.user.business.owner_personal_profile.credit_profile' },
			{ db: 'guarantee_profiles', path: 'finance.owner.guarantee_profile' },
			{ db: 'business_credit_profiles', path: 'client.user.business.credit_profile' },
			{ db: 'finance', path: 'finance' }
		]
	},
	{
		id: 147, containers: [
			{ db: 'guarantee_profiles', path: 'finance.owner.guarantee_profile' },
			{ db: 'business_profiles', path: 'client.user.business.owner_business_profile' },
			{ db: 'business_credit_profiles', path: 'client.user.business.owner_business_profile.credit_profile' },
		]
	},
	{
		id: 148, containers: [
			{ db: 'personal_profiles', path: 'client.user.business.owner_personal_profile' },
			{ db: 'guarantee_profiles', path: 'finance.owner.guarantee_profile' },
      { db: 'phones', path: 'client.user.business.owner_personal_profile.phone' },
		]
	},
	{
		id: 149, containers: [
			{ db: 'addresses', path: 'client.user.business.owner_business_profile.address' }
		]
	},
	{
		id: 150, containers: [
			{ db: 'business_profiles', path: 'client.user.business.owner_business_profile' },
			{ db: 'business_credit_profiles', path: 'client.user.business.owner_business_profile.credit_profile' },
			{ db: 'guarantee_profiles', path: 'finance.owner.guarantee_profile' },
		]
	},
	{
		id: 151, containers: [
			{ db: 'business_profiles', path: 'client.user.business' },
		]
	},
];

const requiredObjects = [
  'amortizations',
  'contracts',
  'finance.guarantee.guaranteeProfile.personal.phone',
  'finance.guarantee.guaranteeProfile.business.phone',
  'finance.guarantee.guaranteeProfile.personal.creditProfile',
  'finance.guarantee.guaranteeProfile.personal.address',
  'finance.guarantee.guaranteeProfile.business.creditProfile',
  'finance.guarantee.guaranteeProfile.business.address',
  'finance.guarantee.guaranteeProfile.business.personal',
  'finance.guarantee.guaranteeProfile.business.personal.phone',

  'finance.owner.guaranteeProfile',
  'finance.owner.guaranteeProfile.personal',
  'finance.owner.guaranteeProfile.business',

  'client.user.business.ownerBusinessProfile',
  'client.user.business.ownerBusinessProfile.address',
  'client.user.business.ownerBusinessProfile.creditProfile',
  'client.user.business.ownerBusinessProfile.personal',
  'client.user.business.ownerBusinessProfile.phone',
  'client.user.business.ownerPersonalProfile',
  'client.user.business.ownerPersonalProfile.address',
  'client.user.business.ownerPersonalProfile.creditProfile',
  'client.user.business.ownerPersonalProfile.phone',

  'relatedProfiles',
  'client.user.personal.address',
  'client.user.personal.creditProfile',
  'client.user.personal',
  'client.user.personal.bankAccounts',
  'client.user.personal.phone',

  'client.user.business.address',
  'client.user.business.personal.address',
  'client.user.business.personal.creditProfile',
  'client.user.business.personal',
  'client.user.business.personal.phone',
  'client.user.business',
  'client.user.business.bankAccounts',
  'client.user.business.phone',
  'splits',
  'supplier.user',
  // 'client.user.personal.creditProfile.address',
];

const arrayPaths = ['contracts', 'client.user.personal.bank_accounts', 'related_profiles', 'amortizations'];

const addressSelectDefinitions = [
  // { id: 1, label: "Misma dirección del cliente", valid: true },
  { id: 2, label: "Si, deseo elegir una ubicación previa.", valid: true },
  { id: 3, label: "No, agregar otra ubicación", valid: true },
];

const addressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
};
const fiscalAddressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
  address_equals_fiscal_address: null
}

const genderDefs = [
  {k: 1, v: "HOMBRE"},
  {k: 2, v: "MUJER"},
];

const verifiedEnumValue = "verificado";
const rejectedEnumValue = "rechazado";

const preapprovePfSectionDefs = [14,32,15];
const preapprovePfaeSectionDefs = [14,15,32];
const preapprovePmSectionDefs = [26,27,16];

const approvePfSectionDefs = [14,15,32,33,35,36,47,34,19,20];
const approvePfaeSectionDefs = [14,15,32,33,35,36,47,34,19,20];
const approvePmSectionDefs = [26,27,16,28,29,30,52,94,36,47,34,97,98,99];
const PFAE_TAX_PROFILE_DEF = 1;
const CROWDFUNDING_LOAN_TYPE_PF = 1;
const CROWDFUNDING_LOAN_TYPE_PFAE_PM = 2;

const obligatedSections = [137];
const verifyPFOSSections = [19,20,21];
const verifyPMOSSections = [123,124,125,141];

const SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE = 14;

export default {
  mixins: [formHelper, inputHelper, dateHelper, docsHelper, formatHelper],
  props: ['id', "person_type"],
  data(){
    return {
      selectedRelatedProfile: null,
      relatedProfilesList: [],
      isGuaranteeSaved: false,
      isOwnerSaved: false,
      currentGroup: null,
      legalRepresentativeIsApplicant: 0,
      errorCode: 0,
      onError: false,

      pmClientType: 0,
      pfClientType: 1,

      flow_id_pf: 4,
      flow_id_pm: 5,

      pfaeExtraSection: 15,
      PFAE_TAX_PROFILE: 1,

      CROWDFUNDING_LOAN_TYPE_PF: CROWDFUNDING_LOAN_TYPE_PF,
      CROWDFUNDING_LOAN_TYPE_PFAE_PM: CROWDFUNDING_LOAN_TYPE_PFAE_PM,

      isMaster: null,

      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,

      flow: {},
      allSections: [],
      sectionGroups: [],

      documentsStates: [],

      tabs: [],

      isMoral: false,
      isPfae: false,
      activeTab: 0,
      isRealPFAE: false,

      missing: {
        enterprise: '',
        acredit: '',
        contact: '',
        residence: '',
        bank: ''
      },

      collections: {
        personal_business_activities_categories: [],
        personal_business_activities: [],
        os_personal_business_activities: [],
        cities: [],
        owner_cities: [],
        gaurantee_cities: [],
        employer_cities: [],
        fiscal_cities: [],
        states: [],
        countries: [],
        personal_occupations: [],
        family_groups: [],
        tools: [],
        business_activities: [],
        genders: genderDefs,
        terms: [],
        banks: [],
        cover_images: [],
        project_addresses: [],
        existingLocations: [],
        neighborhoods: [],

        states_application_address: [],
        cities_application_address: [],
        neighborhoods_application_address: [],
        states_guarantee_profile_address: [],
        cities_guarantee_profile_address: [],
        neighborhoods_guarantee_profile_address: [],
        states_application_business_address: [],
        cities_application_business_address: [],
        neighborhoods_application_business_address: [],
        states_business_guarantee_profile_address: [],
        cities_business_guarantee_profile_address: [],
        neighborhoods_business_guarantee_profile_address: [],
        states_business_owner_address: [],
        cities_business_owner_address: [],
        neighborhoods_business_owner_address: []
      },

      locationDefined: false,
      addressSelectOptions: addressSelectDefinitions,
      payload: {},
      allAddresses: [],
      PCP: {},
      fullLocation: null,

      // objeto principal
      base: {},

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,
      marriedMaritalStatusId: 2,

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],
      totalFields: 0,
      totalValidFields: 0,
      totalInvalidFields: 0,

      validatedDocuments: [],
      allDocumentFields: [],

      isBackoffice: false,
      minScreen: 1100,
      currentSupplierUserId: null,

      preapproveSections: [],
      approveSections: [],

      guaranteeCountryId: null,
      should_fill_mexican_guarantee_address: null,
      should_fill_foreign_guarantee_address: null,

      udis: null,

      slas: {
        min_financed_amount_mxn: null,
        max_financed_amount_mxn: null,
        max_applicant_pf_financed_amount_udis: null,
        min_applicant_age: null,
        max_applicant_age: null,
        min_guarantee_age: null,
        max_guarantee_age: null,
        max_first_project_supplier_total_cost_mxn: null,
        applicant_owner_less_than_age_to_automatic_guarantee_required: null,
        applicant_owner_more_than_age_to_automatic_guarantee_required: null
      },

      cpAddressSource: null,

      technologiesValidation: null,
      //VALIDACIÓN DE OBLIGADOS
      obligatedSections: obligatedSections,
      verifyPFOSSections: verifyPFOSSections,
      verifyPMOSSections: verifyPMOSSections,
      isRejected: false,

      client_has_bank_account_flag: false,
      role: "pf_income_source",
      isCheckinObligatedIncomeSOurces: false,
      incomeSourceValidation: null,
      SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE: SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE,
      knowledgeContractValidator:0,
      uniquePhoneResponse: null
    }
  },
  components: {
    FormField,
    TechnologySelectorControl,
    ApplicantReferencesControl,
    AddressDivider,
    IncomeSourcesSelectorControl,
    BusinessProfileTinsControl,
    PersonalProfileTinsControl
  },
  computed: {
    supplierIsVerified() {
      return this.SupplierVerifiedDate != null;
    },
    is_not_a_different_profile_and_should_fill_curp() {
      return this.selectedRelatedProfile?.value == null && this.should_fill_owner_pm_curp_rfc;
    },
    is_not_a_different_profile_and_should_fill_rfc() {
      return this.selectedRelatedProfile?.value == null && this.should_fill_owner_pm_curp_rfc;
    },
    is_not_a_different_profile() {
      return this.selectedRelatedProfile?.value == null;
    },
    is_a_different_profile() {
      return this.selectedRelatedProfile?.value != null;
    },
    is_guarantee_applicant() {
      return this.base.finance.guarantee?.guarantee_profile?.is_applicant;
    },
    is_guarantee_not_applicant() {
      return !this.base.finance.guarantee.guarantee_profile.is_applicant;
    },
    should_show_is_legal_rep_owner_question() {
      return true;
    },
    ownerPersonalProfile() {
      return this.base.client.user.business.owner_personal_profile;
    },
    ownerBusinessProfile() {
      return this.base.client.user.business.owner_business_profile;
    },
    is_legal_representative_owner() {
      return !!this.base?.client.user.business?.legal_representative_is_owner;
    },
    legal_representative_is_not_owner() {
      return !this.base?.client.user.business?.legal_representative_is_owner;
    },
    is_legal_representative_not_owner() {
      return !this.base.client.user.business.is_owner_letter_needed;
    },
    is_address_mexican() {
      return this.financeGuaranteeProfile?.address.country_id == this.mexicoCountryId;
    },
    is_address_foreign() {
      return this.financeGuaranteeProfile?.address.country_id != this.mexicoCountryId;
    },
    financeGuaranteeProfile() {
      if(this.guaranteeType.value == 1)
        return this.base.finance.guarantee.guarantee_profile.personal;
      if(this.guaranteeType.value == 2)
        return this.base.finance.guarantee.guarantee_profile.business;
    },
    guaranteeType: {
      get() {
        const guaranteeType = this.base?.finance.guarantee?.guarantee_type;
        if(guaranteeType)
          return guaranteeType;
        return 0;
      },
      async set(newValue) {
        this.base.finance.guarantee.guarantee_type = newValue;
        this.runSilentValidation();
      }
    },
    ownerType: {
      get() {
        const ownerType = this.base?.finance.owner?.owner_type;
        if(ownerType)
          return ownerType;
        return 0;
      },
      set(newValue) {
        this.base.finance.owner.owner_type = newValue;
        this.runSilentValidation();
      }
    },
    getIsDifferentProfileText() {
      if(this.selectedRelatedProfile) {
        const currentTab = this.getSectionGroups()[this.activeTab];

        if(currentTab.slug == 'obligated') {
          switch(this.selectedRelatedProfile.text) {
            case 'Dueño de la empresa (persona física)': return 'Los datos del Apoderado o Representante Legal del Obligado solidario corresponden a los del Dueño de la empresa solicitante';
            case 'Representante legal': return 'Los datos del Apoderado o Representante Legal del Obligado solidario corresponden a los del Representante legal del solicitante';
            case 'Solicitante': return 'Los datos del Apoderado o Representante Legal del Obligado solidario corresponden a los del Solicitante';
          }
        }
        if(currentTab.slug == 'owner') {
          switch(this.selectedRelatedProfile.text) {
            case 'Obligado solidario': return 'Los datos del Apoderado o Representante Legal del Dueño corresponden a los del Obligado Solidario';
            case 'Representante legal': return 'Los datos del Apoderado o Representante Legal del Dueño corresponden a los del Representante legal del solicitante';
            case 'Solicitante': return 'Los datos del Apoderado o Representante Legal del Dueño corresponden a los del Solicitante';
          }
        }
      }
    },
    show_guarantee_physical_person_form() {
      return this.guaranteeType.value == 1;
    },
    show_guarantee_moral_person_form() {
      return this.guaranteeType.value == 2;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    isInProcess(){
      return this.base.documentation_complete_date == null
    },
    hasReturnPreRequest(){
      return this.base.status == 1 && this.base.review_date == null;
    },
    isArchived(){
      return this.base.resolution_date !== null;
    },
    isDisabled(){
      return this.isArchived || this.base.status >= 4;
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < this.minScreen;
    },
    currentProjectId(){
      return this.id;
    },
    currentClientId(){
      return this.base.client_id;
    },
    personal(){
      return this.base.client.user.personal;
    },
    business(){
      return this.base.client.user.business;
    },
    fiscal(){
      return this.base.client.user.fiscal;
    },
    pf_guarantee(){
      return this.personal.guarantee_profile;
    },
    pf_guarantee_personal(){
      return this.personal.guarantee_profile.personal;
    },
    legal_owner_document_required(){
      if(this.business){
        return this.business.power_proof_required;
      }
      return false;
    },
    should_send_pf_guarantee_income_files(){
      if(this.pf_guarantee){
        return this.pf_guarantee.income_files_required;
      }
      return false;
    },
    should_send_owner_income_files(){
      if(this.business.personal.guarantee_profile){
        return this.business.personal.guarantee_profile.income_files_required;
      }
      return false;
    },
    should_fill_owner_mexican_address(){
      return this.business.personal.address.country_id == this.mexicoCountryId;
    },
    should_fill_owner_foreign_address(){
      return this.business.personal.address.country_id != this.mexicoCountryId;
    },
    should_fill_owner_pf_mexican_address() {
      return true;
    },
    should_fill_owner_pf_foreign_address() {
      return false;
    },
    should_fill_owner_bdate_gender(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    should_fill_guarantee_profile_bdate_gender(){
      return this.businessGuaranteeProfile.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_rfc_curp_fiscal_rec(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    should_fill_guarantee_profile_rfc_curp_fiscal_rec(){
      return this.businessGuaranteeProfile.citizenship == this.mexicoCountryId;
    },
    client_should_be_married(){
      return this.personal.marital_status == this.marriedMaritalStatusId;
    },
    owner_should_be_married(){
      return this.business.personal.marital_status == this.marriedMaritalStatusId;
    },
    guarantee_profile_should_be_married(){
      return this.business.guarantee_profile.personal.marital_status == this.marriedMaritalStatusId;
    },
    business_should_need_guarantee_profile(){
      return this.business.credit_profile.guarantee_needed != null || this.business.credit_profile.guarantee_needed == 1 ? true : false;
    },
    should_send_guarantee_profile_income_files(){
      return this.business.guarantee_profile.income_files_required;
    },
    pf_guarantee_should_be_married(){
      if(this.guaranteeType.value == 1) 
        return this.financeGuaranteeProfile.marital_status == this.marriedMaritalStatusId;
    },
    is_project_view(){
      return true;
    },
    personalBankAccount(){
      return this.base.client.user.fiscal.bank_accounts[0];
    },
    businessBankAccount(){
      return this.base.client.user.fiscal.bank_accounts[0];
    },
    businessOwner(){
      return this.base.client.user.business.personal;
    },
    businessGuaranteeProfile(){
      return this.base.client.user.business.guarantee_profile.personal;
    },
    client_bank_account_id(){
      if(!this.client_bank_account_exists){
        return null;
      }

      if(this.isMoral){
        return this.businessBankAccount.id
      }
      else {
        return this.personalBankAccount.id;
      }
    },
    client_bank_account_exists(){
      if(this.isMoral){
        return this.businessBankAccount ? this.businessBankAccount.id != null : false;
      }
      else {
        return this.personalBankAccount ? this.personalBankAccount.id != null : false;
      }
    },
    showObligatedTab(){
      return this.base.finance.guarantee_required;
    },
    showObligatedSecondTab(){
      if(this.isMoral){
        return this.business.credit_profile.guarantee_needed;
      }
    },
    showObligatedIncomeSources(){
      return this.base.finance?.guarantee.guarantee_profile?.income_files_required;
    },
    isSFV(){
      return this.base.finance.loan_type == 1;
    },
    showTechnologyTab(){
      return this.supplierIsVerified && this.isSFV;
      // return (!(this.SupplierStatus < 4) && !(this.knowledgeContractValidator < 1));
    },
    // showIncomeSourcesTab(){
    //   return !this.client_has_bank_account_flag;
    // },
    personal_credit_profile(){
      if(this.isMoral){
        return null;
      }
      else {
        if(this.personal){
          return this.personal.credit_profile;
        }
      }
    },
    business_credit_profile(){
      if(!this.isMoral){
        return null;
      }
      else {
        if(this.business){
          return this.business.credit_profile;
        }
      }
    },
    quote_file_is_verified(){
      return (this.validatedDocuments.includes(65));
    },
    project_has_delivery_terms(){
      if(this.base.finance){
        return (this.base.finance.delivery_term > 1 && this.base.finance.delivery_term != null)
      }
      return false;
    },
    client_should_be_working() {
      return this.personal.credit_profile.current_employment == 1 ? true : false;
    },
    client_should_not_be_working() {
      return this.personal.credit_profile.current_employment == 0 ? true : false;
    },
    client_should_be_working_and_not_the_same_address() {
      return (this.personal.credit_profile.current_employment == 1 && this.cpAddressSource == 2) ? true : false;
    },
    client_should_be_working_and_not_the_same_address_and_mexican_address() {
      return (this.personal.credit_profile.current_employment == 1 
              && this.cpAddressSource == 2 
              && this.personal.credit_profile.address.country_id == this.mexicoCountryId) ? true : false;
    },
    client_should_be_working_and_not_the_same_address_and_foreign_address() {
      return (this.personal.credit_profile.current_employment == 1 
              && this.cpAddressSource == 2 
              && this.personal.credit_profile.address.country_id != this.mexicoCountryId) ? true : false;
    },
    client_has_bank_account(){
      return this.base.client.bank_account_availability == 1;
    },
    validAddressSelectOptions(){
      return this.addressSelectOptions.filter(e => e.valid);
    },
    already_obligated_exist(){
        return this.base.finance.guarantee.guarantee_profile_id;
    },
    get_obligated_data(){
      if(this.already_obligated_exist) {
        return this.base.finance.guarantee.guarantee_profile;
      } 
      return false;
    },
    obligated_status(){
      let obligated = this.get_obligated_data;
      if(obligated) {
        let status = "updated";
        if(obligated.rejected_date != null){
          status = "rejected";
        } else if (obligated.approved_date != null){
          status = "approved";
        }
        return status;
      } else {
        return obligated;
      }
    },
    obligated_slug(){
      return "obligated";
    },
    applicant_is_pf(){
      return this.person_type === this.pfClientType;
    },
    is_pf_and_tax_profile_one(){
      return this.project_finances.crowdfunding_loan_type == this.CROWDFUNDING_LOAN_TYPE_PFAE_PM;
    },
    project_finances() {
      return this.base.finance;
    },
    should_block_email() {
      return this.base.status >= 3 ;
    },
    should_block_phone() {
      return this.base.status >= 3 ;
    },
    should_fill_pm_rfc(){
      return this.business.owner_business_profile.nationality == this.mexicoCountryId;
    },
    should_fill_business_tins(){
      return this.business.owner_business_profile.nationality && this.business.owner_business_profile.nationality != this.mexicoCountryId;
    },
    validate_business_profile_tins(){
      if(this.$refs.business_profile_tins_component != null){
        return this.$refs.business_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    should_fill_owner_pm_curp_rfc() {
      return this.business.owner_personal_profile && (this.business.owner_personal_profile.citizenship == this.mexicoCountryId);
    },
    is_mexican_owner() {
      return this.business.owner_personal_profile && (this.business.owner_personal_profile.citizenship == this.mexicoCountryId);
    },
    should_fill_owner_pf_tins() {
      return this.business.owner_personal_profile.citizenship && (this.business.owner_personal_profile.citizenship != this.mexicoCountryId);
    },
    validate_personal_profile_tins() {
      if(this.$refs.business_profile_tins_component != null){
        return this.$refs.business_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    owner_business_profile_address_is_mexican() {
      return this.business.owner_business_profile.address.country_id == this.mexicoCountryId;
    },
    owner_business_profile_is_address_foreign() {
      return !this.owner_business_profile_address_is_mexican;
    }
  },
  async beforeMount() {
    this.loading = true;
    this.isMounted = false;
    this.isBackoffice = process.env.VUE_APP_IS_BACKOFFICE == "true";
    this.minScreen = this.isBackoffice ? 2000 : 1100;
    this.PFAE_TAX_PROFILE = PFAE_TAX_PROFILE_DEF;
    await this.getCollections();
    await this.getProjectData();
    this.setSupplierUserId();
    await this.getProjectStatusInfo();
    this.setClientPersonTypeInfo();
    await this.setCustomIntegrations();
    await this.getFormInfo();
    this.setSectionGroups();
    this.setBlockedDocuments();
    this.setTabFromParams();
    // if(this.showObligatedTab)
    //   this.getRelatedProjectProfiles('obligated');
    // if(this.isMoral && this.is_legal_representative_not_owner)
    //   this.getRelatedProjectProfiles('owner');
    // if(this.isInProcess){
    //   // await this.runSilentValidation(true);
    // }
    this.isMounted = true; 
    this.loading = false;
  },
  watch: {
    selectedRelatedProfile(newVal, oldVal) {
      const selectedOption = newVal;
      const currentTab = this.getSectionGroups()[this.activeTab];

      if(selectedOption && selectedOption.value != oldVal?.value)
        this.saveRelatedProjectProfile(selectedOption.value, currentTab.slug);
    },
    activeTab(newVal, oldVal) {
      const currentTab = this.getSectionGroups()[newVal];
      this.selectedRelatedProfile =  null;

      switch(currentTab.slug) {
        case 'obligated': 
        if(this.guaranteeType.value != 1)
            this.getRelatedProjectProfiles('obligated');
          break;
        case 'owner': 
        if(this.ownerType.value != 1)
            this.getRelatedProjectProfiles('owner');
          break;
      }
    },
    loading : function(newVal, oldVal){
      if(newVal == true){
        this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    showGuaranteeVerifyButtons(section) {
      return this.hasBackofficeAccess && !this.isDisabled && this.isObligatedSection(section.data.id) && this.already_obligated_exist && this.obligated_status == 'updated'
    },
    async saveRelatedProjectProfile(profileId, currentTab) {
      try {
        let guaranteeBase = null;
        let nestedObjectName = null;
        switch(currentTab) {
          case 'obligated':
              guaranteeBase = this.base.finance.guarantee;
              nestedObjectName = 'guarantee';
              break;
          case 'owner':
              guaranteeBase = this.base.finance.owner;
              nestedObjectName = 'owner';
              break;
        }

        const payload = {
          finance: {
            id: this.base.finance.id,
            [nestedObjectName]: {
              id: guaranteeBase?.id,
              guarantee_type: { 
                value: 2 
              },
              guarantee_profile: {
                id: guaranteeBase?.guarantee_profile?.id,
                same_profile_as: profileId,
                business: {
                  id: guaranteeBase?.guarantee_profile?.business?.id,
                  nationality: 700
                }
              }
            }
          }
        }

        this.loading = true;
        await axios.put(`/api/v2/projects/${this.id}/deepUpdate`, payload);
        await this.reloadFormInfo();
        this.loading = false;
      } 
      catch (error) {
        console.log(error);
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
    },
    async getRelatedProjectProfiles(currentTab) {
      try {
        let guaranteeProfileId = 0;
        let isGuarantee = 1;
        switch(currentTab) {
          case 'obligated': 
            guaranteeProfileId = this.base.finance.guarantee?.guarantee_profile.id || 0;
            break;
          case 'owner': 
            guaranteeProfileId = this.base.finance.owner?.guarantee_profile.id || 0;
            isGuarantee = 0;
            break;
        }

        const personType = this.isMoral ? 1 : 0;
        let result = await axios.get(`api/v2/projects/getRelatedProjectProfiles/${this.id}/${guaranteeProfileId}/${personType}/${isGuarantee}`);

        if(result) {
          result = result.data;
          this.relatedProfilesList = result.relatedProfilesList;
          if(this.relatedProfilesList.length > 0)
            this.relatedProfilesList.push({ value: null, text: 'Otr@' });
          this.selectedRelatedProfile = this.relatedProfilesList.find((element) => element.value == result.selectedRelatedProfile);
        }
      } 
      catch (error) {
        console.log(error);
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
    },
    isSaveButtonShown(section) {

      if(section.data.id == 47) {
        return false;
      }

      if(section.data.id == 123 && this.is_a_different_profile)
        return false;
      return !this.isDisabled && !section.data.skipBtn
    },
    getSavedGuaranteeType() {
      if(this.base.finance.guarantee && this.base.finance.guarantee.guarantee_profile) {
        const guaranteeProfile = this.base.finance.guarantee.guarantee_profile;
        if(guaranteeProfile.personal) {
          this.isGuaranteeSaved = true;
          return { text: 'Persona física', value: 1 };
        }
        if(guaranteeProfile.business) {
          this.isGuaranteeSaved = true;
          return { text: 'Persona moral', value: 2 };
        }
      }
    },
    getSavedOwnerType() {
      const businessProfile = this.base.client.user.business;
      if(businessProfile) {
        if(businessProfile.owner_business_profile) {
          this.isOwnerSaved = true;
          return { text: 'Persona moral', value: 2 };
        }
        if(businessProfile.owner_personal_profile) {
          this.isOwnerSaved = true;
          return { text: 'Persona física', value: 1 };
        }
      }
    },
    handleLegalRepTypeChange() {
      if(!this.isMoral)
        this.base.finance.guarantee.guarantee_profile.is_applicant = this.legalRepresentativeIsApplicant;
    },
    setIsObligatedSectionShown(currentSection, conditionToShow) {
      if(this.isInSection(currentSection, 19, 20, 21) && this.guaranteeType.value == 1)
        return conditionToShow;
      if(this.isInSection(currentSection, 122, 123, 124, 125, 141) && this.guaranteeType.value == 2)
        return conditionToShow;
      if(currentSection == 137)
        return conditionToShow;
      return;
    },
    setIsOwnerSectionShown(currentSection, conditionToShow) {
      if(this.ownerType.value == 1 && this.isInSection(currentSection, 143, 144, 145, 146))
        return conditionToShow;
      if(this.ownerType.value == 2 && this.isInSection(currentSection, 147, 148, 149, 150, 151))
        return conditionToShow;
      if(currentSection == 142)
        return conditionToShow;
      return;
    },
    isInSection(currentSection, ...sections) {
      for (const section of sections) {
        if(section == currentSection)
          return true;
      }
    },
    /*
    TODO:DELETE 03/12/2024
    async checkKnowledgeContractSigned(){
      try {
        let res = await axios.get(`/api/supplier/contracts/${this.base.supplier_id}/verify/${this.SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE}`); // VALIDA SI LA CONSTANCIA CONOCIMIENTO YA FUE ACEPTADA
        this.knowledgeContractValidator = res.data.length;
      } catch (error) {
        console.log(error);
        this.warn(error);
      }
    },*/
    checkIsMounted(){
      return this.isMounted;
    },
    setSupplierUserId(){
      this.currentSupplierUserId = this.base.supplier.user.id;
    },
    altRadioText(item_id){
      if(item_id == 1){
        let ad = null;
        if(this.clientAddress != null){
          ad = this.clientAddress.full_address;
        }
        ad = (ad == null || ad == "") ? "Sin datos" : ad;
        return `(${ad})`;
      }
    },
    setTabFromParams(){ 
      let tab = this.$route.query.tab;
      if(tab && tab == "tecnologias"){
        let index = this.tabs.findIndex(f => f.slug == "technologies");
        if(!this.isPfae){
          if(this.showObligatedSecondTab){
            this.activeTab = index;
          } else {
            this.activeTab = index -1;
          }
        }
        else{
          if(this.showObligatedTab){
            this.activeTab = index;
          }
          else {
            this.activeTab = index -1;
          }
        }
      } else if(tab && tab == "informacion_bancaria"){
        this.activeTab = this.tabs.findIndex(f => f.slug == "income_sources");
      }
    },
    titleForTab(group){
      if(group.failed == 0){
        return group.title;
      }
      else 
        return `${group.title} ( ${group.failed} )`;
    },
    iconForTab(group){
      if(group.failed == 0){
        return "icon-check-circle";
      } else {
        return group.icon;
      }
    },
    classForTab(group){
      if(group.failed == 0){
        return "success";
      } else {
        return "danger";
      }
    },
    updateNetIncome(val){
      let inc = this.$refs['computed_net_income'];
      if(inc){
        inc[0].updateComputed(val);
      }
    },
    getSectionGroups(){
      return this.sectionGroups.filter(f => {
        if(f.slug == "obligated")
          return this.showObligatedTab;
        if(f.slug == "obligated_income_sources")
          return this.showObligatedIncomeSources;
        if(f.slug == "owner_income_sources")
          return this.base.finance.owner?.guarantee_profile?.income_files_required;
        if(f.slug == "owner")
          return !this.business.legal_representative_is_owner;
        if(f.slug == "representative_income_sources")
          return this.base.finance?.representative_income_sources_required;
        return true;
      });
    },
    getSectionsForGroup(group){
      if(!this.hasBackofficeAccess && !this.legal_owner_document_required){
        if(this.quote_file_is_verified){
          return group.sections.filter(f=> {
            const conditionToShow = f.data.fields.length > 0 && f.data.id != 29;
            switch(group.slug) {
              case 'obligated': return this.setIsObligatedSectionShown(f.data.id, conditionToShow);
              case 'owner': return this.setIsOwnerSectionShown(f.data.id, conditionToShow);
              default: 
                if(f.data.id == 52) {
                  if(this.business && !this.is_legal_representative_owner)
                    return;
                }
                return conditionToShow;
            }
          });
        }
        else {
          return group.sections.filter(f => {
            const conditionToShow = f.data.fields.length > 0 && f.data.id != 29 && f.data.id != 50 && f.data.id != 51;
            switch(group.slug) {
              case 'obligated': return this.setIsObligatedSectionShown(f.data.id, conditionToShow);
              case 'owner': return this.setIsOwnerSectionShown(f.data.id, conditionToShow);
              default: 
                if(f.data.id == 52) {
                  if(this.business && !this.is_legal_representative_owner)
                    return;
                }
                return conditionToShow;
            }
          });
        }
      }
      else {
        if(this.quote_file_is_verified){
          return group.sections.filter(f => {
            const conditionToShow = f.data.fields.length > 0;
            switch(group.slug) {
              case 'obligated': return this.setIsObligatedSectionShown(f.data.id, conditionToShow);
              case 'owner': return this.setIsOwnerSectionShown(f.data.id, conditionToShow);
              default: 
                if(f.data.id == 52) {
                  if(this.business && !this.is_legal_representative_owner)
                    return;
                }
                return conditionToShow;
            }
          });
        }
        else {
          return group.sections.filter(f => {
            const conditionToShow = f.data.fields.length > 0 && f.data.id != 50 && f.data.id != 51;
            switch(group.slug) {
              case 'obligated': return this.setIsObligatedSectionShown(f.data.id, conditionToShow);
              case 'owner': return this.setIsOwnerSectionShown(f.data.id, conditionToShow);
              default: 
                if(f.data.id == 52) {
                  if(this.business && !this.is_legal_representative_owner)
                    return;
                }
                return conditionToShow;
            }
          });
        }
      }
    },
    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getProjectData();
      await this.setCustomIntegrations();
      await this.setSectionGroups();
      this.reloadSectionGroups();
      this.setClientPersonTypeInfo();
      this.set_project_financed_interests_percentage();
      this.isRefreshing = false;
    },
    async saveGroupChanges(section){
      // if(this.PCP && this.PCP.id != null && this.personal.credit_profile.employer_address_id == null && this.payload.source_address == 2){
      //   this.personal.credit_profile.employer_address_id = this.PCP.id;
      // }

      // if(this.obligated_status == "rejected" && this.isObligatedSection(section.id, true) ){
      //   let r = await this.validateOSClean();
      //   if(r){
      //     this.SaveFullOS(r); // SE GUARDAN MULTIPLES SECCIONES
      //     this.loading = false;
      //     this.validatedFields = [];
      //   } else {
      //     return;
      //   }
      // } else {
        let result = await this.runValidations(section);
        if(!result){
          this.missingFieldsNotif();
        }
        else {
          this.loading = true;
  
          //guardar solo la informacion que se ha modificado
          this.basePayload = this.collectFormData(section);
          
          if(this.basePayload.finance?.guarantee) 
            this.basePayload.finance.guarantee.guarantee_type = this.base.finance.guarantee.guarantee_type;
          
          // conversion de finance.deposit_percentage
          /*
          let finance = this.basePayload.finance;
          if(finance && finance.deposit_percentage_unit){
            finance.deposit_percentage = finance.deposit_percentage_unit / 100;
          }
  
          if(finance && finance.annual_interest_percentage_unit){
            finance.annual_interest_percentage = finance.annual_interest_percentage_unit / 100;
          }*/
  
          if(this.objectIsEmpty(this.basePayload)){
            //this.notFieldsToUpdate();
            this.saveSuccessNotif("Operación exitosa"
            , "Información actualizada correctamente. ¡No olvides enviar la solicitud completa! Una vez que concluyas con el proceso, da clic en el botón “Enviar solicitud completa” en el detalle del proyecto para concluir con el proceso."
            , 6000);
            this.loading = false;
            return;
          }
  
          this.basePayload.id = this.base.id;
  
          try {
            // ejecutar guardado
            const res = await axios.put(`/api/v2/projects/${this.id}/deepUpdate`, this.basePayload);
  
            // solicitar la informacion actualizada del modelo
            await this.reloadFormInfo();
  
            this.saveSuccessNotif("Operación exitosa"
            , "Información actualizada correctamente. ¡No olvides enviar la solicitud completa! Una vez que concluyas con el proceso, da clic en el botón “Enviar solicitud completa” en el detalle del proyecto para concluir con el proceso."
            , 6000);

            // this.runSilentValidation();
          }
          catch (error) {
            let mssg = "Algo salió mal."
            if(error.response && error.response.data && error.response.data.message){
              mssg = error.response.data.message;
            }
            this.failedOperationNotif(null, mssg);
          }
  
          this.loading = false;
          
        }
      // }
    },

    async runValidations(section){
      if(section.data.id == 37 && this.payload.source_address == null){
        this.missingFieldsNotif("Seleccione la ubicación", "Debes elegir la ubicación de esta instalación", 6000);
        return false;
      }
      // validar componentes tipo FormField
      let gslug = "section" + section.data.id;
      let res1 = await this.validateFormFields(gslug, section.data.id);
      if(!res1){
        return false;
      }

      // validar inputs instanciados en la vista
      /* let res2 = await this.$validator.validateAll();
       if(!res2){
         return false;
       }*/

      // inspeccionar los campos para ejecutar validaciones especiales
      let spValidation = await this.checkFormFieldsSpecialValidations(gslug);
      if(!spValidation){
        return false;
      }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales

      let sp_failed = false;

      section.data.fields.filter(f => f.special_definition == 1).forEach(f => {
        // considerar los campos habilitados (visibles), con validaciones especiales
        if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
          let res = this[f.special_validation.method]();

          if(!res){
            this.errors.add({
              field: f.fname,
              msg: f.special_validation.error
            });
            this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
            sp_failed = true;
          }
        }
      });

      if(sp_failed){
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    collectFormData(section){
      let payload = {};
      section.containers.forEach(cc => {
        let fields = this.validatedFields.filter(vf => vf.db == cc.db);
        if(fields.length < 1){
          return;
        }

        if(cc.path == "base"){
          fields.forEach(f => {
            payload[f.fname] = cc.container[f.fname];
          });
        }
        else {
          let obj = null;
          if(arrayPaths.includes(cc.path)){
            // se integran todos los objetos de la coleccion
            obj = this.nestedFieldValue(this.base, cc.path);
          }
          else {
            obj = {id: cc.container.id};
            fields.forEach(f => {
              obj[f.fname] = cc.container[f.fname];
            });
          }
          // ajuste para objetos tipo array
          this.setNestedObjectWithIds(payload, this.base, cc.path, obj);
          this.get_project_financed_interests_percentage(payload);
        }
      });
      return payload;
    },
    async validateFormFields(refGroup, section_id = null){
      let allValid = true;
      if(this.obligated_status != "rejected" || !this.verify_obligated_section(section_id)){
        this.validatedFields = [];
      }
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let r = await this.$refs[refGroup][p].checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },
    async checkFormFieldsSpecialValidations(refGroup){
      let spvalid = true;
      let grefs = this.$refs[refGroup];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let bck = f.checkBackofficePermit();
        if(!bck){
          continue;
        }

        let sp = f.specialValidation();
        if(sp != null && sp.method in this){
          let res = await this[sp.method]();
          let n = f.rawFieldName;
          if(f.f.db_field === "rfc") {
            if(res.length === 0) {
              continue;
            } else {
              spvalid = false;
              res.forEach(item => {
                this.errorNotif(item.n, item.e, 10000);
              })
            }
          }
          if(!res){
            spvalid = false;
            if(sp.custom_error){
              let err = this[sp.custom_error]();
              f.setError(err);
              this.errorNotif(n, err, 10000);
            }
            else {
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
            }
          }
        }
      }
      return spvalid;
    },

    // especiales para backoffice
    async requestPreapproval(){
      let ok  = await this.checkForBackofficeVerification(false);
      if(!ok){
        return false;
      }

      return true;
    },

    async requestApproval(){
      let ok  = await this.checkForBackofficeVerification(true);
      if(!ok){
        return false;
      }

      return true;
    },

    async suggestVerification(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: '¡No olvides enviar la solicitud completa!',
        text: `Una vez que concluyas con el proceso, da clic en el botón “Enviar solicitud completa” en el detalle del proyecto para concluir con el proceso.`,
        acceptText: "Enviar solicitud ahora",
        cancelText: 'Cancelar',
        accept: this.doRequestVerification
      });
    },

    async requestVerification(){
      let ok  = await this.checkForValidVerification();
      if(!ok){
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Solicitar revisión de solicitud',
        text: `¿Estás seguro que deseas enviar la solicitud a revisión? El equipo de RedGirasol procederá a realizar la validación de la información.`,
        acceptText: "Enviar solicitud",
        cancelText: 'Cancelar',
        accept: this.doRequestVerification
      });
    },

    async doRequestVerification(){
      this.showLoading(true);
      try {
        let payload = { project_id: this.id };
        let res = await axios.post(`/api/v2/projects/${this.id}/sendRequestComplete`, payload);
        if(res.data == "success"){
          this.saveSuccessNotif("Solicitud enviada a revisión", "La solicitud ha sido enviada a revisión. En breve tendrás respuesta.", 10000);
          this.$emit("on-request-complete");
          await this.getProjectData();
        }
      } catch (error) {
        console.log(error);
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    async checkForValidVerification(){
      this.showLoading(true)
      // TODO verificar datos desde back
      await this.runSilentValidation();
      this.showLoading(false)
      if(this.totalInvalidFields > 0){
        this.errorNotif("Campos pendientes",
          "Existen campos pendientes para realizar la verificación. Por favor revisa las pestañas que indiquen un número de pendientes para ver el detalle.", 10000);
        return false;
      }
      return true;
    },

    async checkForBackofficeVerification(approve, isSupplier = false){
      this.showLoading(true)
      let m = approve ? "aprobación" : "preaprobación";
      let text = "Existen campos pendientes para realizar la ";
      // TODO verificar datos desde back
      if(isSupplier){
        await this.runPreRequestSilentValidation();
      } else {
        await this.runBackofficeSilentValidation(approve);
      }
      this.showLoading(false)
      if(this.totalInvalidFields > 0){
        if(isSupplier){
          text = "Existen campos pendientes para enviar la pre-solicitud a revisión."
          m = "";
        }
        this.errorNotif("Campos pendientes",
          text + m, 10000);
        return false;
      }
      return true;
    },

    async checkForOSVerification(){
      this.showLoading(true)
      let text = "Existen campos pendientes para realizar la verificación del Obligado Solidario";
      // TODO verificar datos desde back
      await this.runPreRequestSilentValidation(true);
      this.showLoading(false)
      if(this.totalInvalidFields > 0){
        this.errorNotif("Campos pendientes",
          text, 10000);
        return false;
      }
      return true;
    },

    resetCounters(){
      this.totalFields = 0;
      this.totalValidFields = 0;
      this.totalInvalidFields = 0;
      this.accreditationsMissing = null;
    },
    async runSilentValidation(isAutomatic = false){
      this.resetCounters();
      let validator = new Validator();
      let container;
      await this.asyncForEach(this.sectionGroups, async (group) => {
        group.missing = [];
        group.failed = 0;
        if(group.slug == "obligated" && !this.showObligatedTab){
          return;
        } else if(group.slug == "obligated_second" && !this.showObligatedSecondTab) {
          return;
        } else if(group.slug == "obligated_income_sources" && !this.showObligatedIncomeSources){
          return;
        } 
        else if(group.slug == "owner_income_sources" && !this.base.finance.owner?.guarantee_profile?.income_files_required)
          return;
        else if(group.slug == "owner" && this.business.legal_representative_is_owner)
          return;
        else if(group.slug == "owner" && !this.business.legal_representative_is_owner) {
          if(!this.ownerType || this.ownerType.value == 0) {
            this.totalInvalidFields += 1;
            this.errorNotif("Dueño de la empresa pendiente", "Existen campos pendientes para realizar en la pestaña de Dueño de la empresa", 10000);
          }
        }
        else if(group.slug == "representative_income_sources" && !this.base.finance?.representative_income_sources_required)
          return;
        else if(group.slug == "obligated" && this.base.finance.guarantee_required) {
          if(!this.guaranteeType || this.guaranteeType.value == 0) {
            this.totalInvalidFields += 1;
            this.errorNotif("Obligado Solidario pendiente", "Existen campos pendientes para realizar en la pestaña de Obligado Solidario", 10000);
          }
        }

        await this.asyncForEach(group.sections, async (section) => {

          if(group.slug == 'obligated') {
            if(!this.guaranteeType || this.guaranteeType.value == 0)
              return;
            if(this.guaranteeType.value == 1 && !this.isInSection(section.data.id, 19, 20, 21))
              return;
            if(this.guaranteeType.value == 2 && !this.isInSection(section.data.id, 122, 123, 124, 125, 141))
              return;
          }
          if(group.slug == 'owner') {
            if(!this.ownerType || this.ownerType.value == 0)
              return;
            if(this.ownerType.value == 1 && !this.isInSection(section.data.id, 143, 144, 145, 146)) 
              return;
            if(this.ownerType.value == 2 && !this.isInSection(section.data.id, 147, 148, 149, 150, 151))
              return;
          }
          if(section.data.id == 52) {
            if(this.business && !this.is_legal_representative_owner)
              return;
          }

          await this.asyncForEach(section.data.fields, async (f) => {
            container = this.getContainerForField(section, f);
            if(this.notDocumentNorPrivate(f)){
              if(this.isAutoFormField(f)){
                let res = await this.evaluateFieldCompletition(f, container, this.evaluateCondition, validator);
                //  console.log(section.data.id + ", " + f.fname + "=> " + res);
                if(res != null){
                  if(res == false){
                    section.failed += 1;
                    group.failed += 1;
                    group.missing.push({n: f.field_name, e: "Campo requerido", sectionId: section.data.id} );
                    this.totalInvalidFields += 1;
                  }
                }

                // considerar los campos habilitados (visibles), con validaciones especiales
                if(section.data.id === 47 && this.isPfae){
                  if(f.special_validation != null && f.special_validation.method != null) {
                    let res = await this[f.special_validation.method]();
                    if(!res){
                      section.failed += 1;
                      group.failed += 1;
                      group.missing.push({n: f.field_name, e:f.special_validation.error, sectionId: section.data.id} );
                      this.totalInvalidFields += 1;
                    }
                  }
                }

                //validación de rfc único y formato
                if(f.db_field === "rfc" && f.special_validation){
                  let res = await this[f.special_validation.method]();
                  let errors = res.length;
                  if(errors > 0){
                    section.failed += errors;
                    group.failed += errors;
                    this.totalInvalidFields += errors;
                    res.forEach(item => {
                      group.missing.push({n: item.n, e: item.e } );
                    })
                  }
                }
              }
              else if(f.special_definition == 1 && f.hard_validation){
                this.currentGroup = group;

                this.isCheckinObligatedIncomeSOurces = false;
                if(group.slug === "obligated_income_sources"){
                  this.isCheckinObligatedIncomeSOurces = true;
                }
                let res = await this[f.hard_validation.method]();
                if(!res){
                  section.failed += 1;
                  group.failed += 1;
                  if(f.hard_validation.custom_error != null){
                    const customError = await this[f.hard_validation.custom_error]();
                    group.missing.push({n: f.field_name, e:customError, sectionId: section.data.id } );
                  }
                  else {
                    group.missing.push({n: f.field_name, e:f.hard_validation.error, sectionId: section.data.id } );
                  }
                  this.totalInvalidFields += 1;
                }
              }
              this.totalFields += 1;
            }
          });

          // section.data.documentFields.forEach(df => {
          //   if(!df.loadedState && df.documentSet.is_required == 1 && df.formField.bo_private != 1){
          //     if(df.formField.show_conditioned){
          //       let inc = this.evaluateCondition(df.formField.condition);
          //       if(inc != true){
          //         return;
          //       }
          //     }
          //     section.failed += 1;
          //     group.failed += 1;
          //     this.totalInvalidFields += 1;
          //     group.missing.push({n: df.documentSet.name, e: "Documento requerido", sectionId: section.data.id} );
          //   }
          //   else if(df.loadedState && df.loadedState.is_verified == rejectedEnumValue){
          //     section.failed += 1;
          //     group.failed += 1;
          //     this.totalInvalidFields += 1;
          //     group.missing.push({n: df.documentSet.name, e: "Documento en estado rechazado", sectionId: section.data.id} );
          //   }
          // })
        })
      });

      if(this.base.finance.guarantee_required && this.obligated_status == "rejected"){
        this.totalInvalidFields += 1;
        this.errorNotif("Obligado Solidario Rechazado", "El obligado solidario fue rechazado, para continuar con el proceso será necesario que actualices la información con un nuevo obligado solidario.");
        return;
      }

      if(isAutomatic && this.totalInvalidFields === 0){ // SI YA NO ENCUENTRA ERRORES, SUGIERE ENVÍAR LA SOLICITUD A REVISIÓN.
        this.suggestVerification();
      }
    },

    async runBackofficeSilentValidation(approve){
      this.resetCounters();
      let validator = new Validator();
      let container;
      this.includedSections = approve == true ? this.approveSections : this.preapproveSections;
      await this.asyncForEach(this.sectionGroups, async (group) => {
        group.missing = [];
        group.failed = 0;
        if(group.slug == "obligated" && !this.showObligatedTab){
          return;
        } else if(group.slug == "obligated_second" && !this.showObligatedSecondTab) {
          return;
        } else if(group.slug == "obligated_income_sources" && !this.showObligatedIncomeSources){
          return;
        }

        await this.asyncForEach(group.sections, async (section) => {

          if(group.slug == 'obligated') {
            if(!this.guaranteeType || this.guaranteeType.value == 0)
              return;
            if(this.guaranteeType.value == 1 && !this.isInSection(section.data.id, 19, 20, 21))
              return;
            if(this.guaranteeType.value == 2 && !this.isInSection(section.data.id, 122, 123, 124, 125, 141))
              return;
          }
          if(group.slug == 'owner') {
            if(!this.ownerType || this.ownerType.value == 0)
              return;
            if(this.ownerType.value == 1 && !this.isInSection(section.data.id, 143, 144, 145, 146)) 
              return;
            if(this.ownerType.value == 2 && !this.isInSection(section.data.id, 147, 148, 149, 150, 151))
              return;
          }
          if(section.data.id == 52) {
            if(this.business && !this.is_legal_representative_owner)
              return;
          }
          if(!this.includedSections.includes(section.data.id)){
            return;
          }

          await this.asyncForEach(section.data.fields, async (f) => {
            container = this.getContainerForField(section, f);
            if(this.isAutoFormField(f)){
              let res = await this.evaluateFieldCompletition(f, container, this.evaluateCondition, validator);
              //  console.log(section.data.id + ", " + f.fname + "=> " + res);
              if(res != null){
                if(res == false){
                  section.failed += 1;
                  group.failed += 1;
                  group.missing.push({n: f.field_name, e: "Campo requerido", sectionId: section.data.id} );
                  this.totalInvalidFields += 1;
                }
              }

              // considerar los campos habilitados (visibles), con validaciones especiales
              if(section.data.id === 47 && this.isPfae){
                if(f.special_validation != null && f.special_validation.method != null) {
                  let res = await this[f.special_validation.method]();
                  if(!res){
                    section.failed += 1;
                    group.failed += 1;
                    group.missing.push({n: f.field_name, e:f.special_validation.error, sectionId: section.data.id} );
                    this.totalInvalidFields += 1;
                  }
                }
              }
            }
            else if(f.special_definition == 1 && f.hard_validation){
              this.isCheckinObligatedIncomeSOurces = false;
              if(group.slug === "obligated_income_sources"){
                this.isCheckinObligatedIncomeSOurces = true;
              }
              let res = await this[f.hard_validation.method]();
              if(!res){
                section.failed += 1;
                group.failed += 1;
                group.missing.push({n: f.field_name, e:f.hard_validation.error, sectionId: section.data.id } );
                this.totalInvalidFields += 1;
              }
            }
            this.totalFields += 1;
          });

          section.data.documentFields.forEach(df => {
            if(!df.loadedState && df.documentSet.is_required == 1 && df.formField.bo_private != 1){
              if(df.formField.show_conditioned){
                let inc = this.evaluateCondition(df.formField.condition);
                if(inc != true){
                  return;
                }
              }
              section.failed += 1;
              group.failed += 1;
              this.totalInvalidFields += 1;
              group.missing.push({n: df.documentSet.name, e: "Documento requerido", sectionId: section.data.id} );
            }
            else if(df.loadedState && df.loadedState.is_verified != verifiedEnumValue){
              section.failed += 1;
              group.failed += 1;
              this.totalInvalidFields += 1;
              group.missing.push({n: df.documentSet.name, e: "Documento no verificado", sectionId: section.data.id} );
            }
          });
        });
      });

      if(approve && this.obligated_status == "rejected"){
        this.totalInvalidFields += 1;
        this.errorNotif("Obligado Solidario Rechazado", "El obligado solidario fue rechazado, para continuar con el proceso será necesario que se actualice la solicitud con un nuevo obligado solidario.");
        return;
      }
    },

    async runPreRequestSilentValidation(isObligated = false){
      this.resetCounters();
      let validator = new Validator();
      let container;
      if(!isObligated){
        this.includedSections = this.is_pf_and_tax_profile_one ? this.preapproveSections : [14,32];
      } else {
        this.includedSections = this.guaranteeType.value == 2 ? verifyPMOSSections : verifyPFOSSections;
      }

      await this.asyncForEach(this.sectionGroups, async (group) => {
        group.missing = [];
        group.failed = 0;
        await this.asyncForEach(group.sections, async (section) => {
          if(!this.includedSections.includes(section.data.id)){
            return;
          }

          await this.asyncForEach(section.data.fields, async (f) => {
            container = this.getContainerForField(section, f);
            if(this.notDocumentNorPrivate(f)){
              if(this.isAutoFormField(f)){
                let res = await this.evaluateFieldCompletition(f, container, this.evaluateCondition, validator);
                //  console.log(section.data.id + ", " + f.fname + "=> " + res);
                if(res != null){
                  if(res == false){
                    section.failed += 1;
                    group.failed += 1;
                    group.missing.push({n: f.field_name, e: "Campo requerido", sectionId: section.data.id } );
                    this.totalInvalidFields += 1;
                  }
                }
              }
              else if(f.special_definition == 1 && f.hard_validation){
                let res = await this[f.hard_validation.method]();
                if(!res){
                  section.failed += 1;
                  group.failed += 1;
                  if(f.hard_validation.custom_error != null){
                    const customError = await this[f.hard_validation.custom_error]();
                    group.missing.push({n: f.field_name, e:customError, sectionId: section.data.id } );
                  }
                  else {
                    group.missing.push({n: f.field_name, e:f.hard_validation.error, sectionId: section.data.id } );
                  }
                  this.totalInvalidFields += 1;
                }
              }
              this.totalFields += 1;
            }
          });

          section.data.documentFields.forEach(df => {
            if(!df.loadedState && df.documentSet.is_required == 1 && df.formField.bo_private != 1){
              if(df.formField.show_conditioned){
                let inc = this.evaluateCondition(df.formField.condition);
                if(inc != true){
                  return;
                }
              }
              section.failed += 1;
              group.failed += 1;
              this.totalInvalidFields += 1;
              group.missing.push({n: df.documentSet.name, e: "Documento requerido", sectionId: section.data.id} );
            }
            else if(df.loadedState && df.loadedState.is_verified == rejectedEnumValue){
              section.failed += 1;
              group.failed += 1;
              this.totalInvalidFields += 1;
              group.missing.push({n: df.documentSet.name, e: "Documento en estado rechazado", sectionId: section.data.id} );
            }
          });
        });
      });
    },

    async preRequestVerification(){
      let ok  = await this.checkForBackofficeVerification(false, true);
      if(!ok){
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Solicitar revisión de pre-solicitud',
        text: `¿Estás seguro que deseas enviar la pre-solicitud a revisión? El equipo de RedGirasol procederá a realizar la validación de la información.`,
        acceptText: "Enviar pre-solicitud",
        cancelText: 'Cancelar',
        accept: this.doPreRequestVerification
      });
    },

    async doPreRequestVerification(){
      this.showLoading(true);
      try {
        let res = await axios.post(`/api/project/${this.id}/sendPreRequestToReview`);
        if(res.data == "success"){
          this.saveSuccessNotif("Pre-solicitud enviada a revisión", "La pre-solicitud ha sido enviada a revisión. En breve tendrás respuesta.", 10000);
          await this.getProjectData();
        }
      } catch (error) {
        console.log(error);
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },

    async onFieldChange(method){
      if(method != null && method in this){
        await this[method]();
      }
    },
    /* evaluator */
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'user'){
        if(cls_2 == 'supplier'){
          return this.currentSupplierUserId;
        }
        else if(cls_2 == 'applicant'){
          return this.base.client.user.id;
        }
      }
      else if(cls == 'project'){
        return this.currentProjectId;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    setClientPersonTypeInfo(){
      this.isMoral = this.person_type == this.pmClientType;
      this.isPfae = this.person_type == this.pfClientType;
      this.tabs = this.isMoral ? pmTabsDefs : pfTabsDefs;

      this.preapproveSections = this.isMoral ? preapprovePmSectionDefs : preapprovePfSectionDefs;
      this.approveSections = this.isMoral ? approvePmSectionDefs : approvePfSectionDefs;

      if(this.isPfae && this.is_pf_and_tax_profile_one){
        this.isRealPFAE = true;
        this.preapproveSections = preapprovePfaeSectionDefs;
        this.approveSections = approvePfaeSectionDefs;

        // agregar seccion de constancia fiscal
        if(!this.tabs[0].sections.find(f => f == this.pfaeExtraSection)){
          this.tabs[0].sections.push(this.pfaeExtraSection);
        }
      }
    },
    async setCustomIntegrations(){
      // direcciones personal profile
      let padd_sid = this.nestedFieldValue(this.base, 'client.user.personal.address.state_id');
      if(padd_sid != null){ await this.reload_personal_cities() }

      // direcciones business profile
      let badd_sid = this.nestedFieldValue(this.base, 'client.user.business.address.state_id');
      if(badd_sid != null){ await this.reload_business_cities() }

      let owner_sid = this.nestedFieldValue(this.base, 'client.user.business.personal.address.state_id');
      if(owner_sid != null){ await this.reload_business_owner_cities() }

      let guaran_cid = this.nestedFieldValue(this.base, 'client.user.personal.guarantee_profile.personal.address.country_id');
      if(guaran_cid != null){ this.update_personal_guarantee_country_id() }

      let guaran_sid = this.nestedFieldValue(this.base, 'client.user.personal.guarantee_profile.personal.address.state_id');
      if(guaran_sid != null){ await this.reload_personal_guarantee_cities() }

      // let employer_add_id = this.nestedFieldValue(this.base, 'client.user.personal.credit_profile.employer_address_id');
      let client_add_id = this.nestedFieldValue(this.base, 'client.user.personal.address_id');
      let employer_add_id = null;

      if(employer_add_id != null && client_add_id != null){
        if(employer_add_id == client_add_id){
          this.base.client.user.personal.credit_profile.select_address_source = 1;
          this.cpAddressSource = 1;
        }
        else {
          this.base.client.user.personal.credit_profile.select_address_source = 2;
          this.cpAddressSource = 2;
        }
      }

      let employer_sid = this.nestedFieldValue(this.base, 'client.user.personal.credit_profile.address.state_id');
      if(employer_sid != null){ await this.reload_personal_credit_profile_cities() }

      // conversion finance.deposit_percentage
      /*
      let finance = this.base.finance;
      if(finance && finance.deposit_percentage != null){
        finance.deposit_percentage_unit = this.decimalsFormat(finance.deposit_percentage * 100, 2);
      }

      if(finance && finance.annual_interest_percentage != null){
        finance.annual_interest_percentage_unit = this.decimalsFormat(finance.annual_interest_percentage * 100, 2);
      }*/

    },
    setSectionGroups(){
      this.sectionGroups = [];
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug, disabled: false }; //, vModel: this.getModelForGroup(t.slug) };
        let sections = [];

        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id), failed: 0 };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        group.failed = 0;

        if(group.slug === "technologies" && !this.showTechnologyTab){
          group.disabled = true;
        }

        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    setBlockedDocuments(){
      this.validatedDocuments.forEach(doc => {
        this.allSections.forEach(section => {
          let ffs = section.fields.filter(f => f.block_by_document_id == doc);
          ffs.forEach(fd => fd.blockedByDocument = true);

          if(ffs.length == section.fields.filter(f => f.doc_id == null ).length){
            section.skipBtn = true;
          }
        })
      })
    },
    getContainerForField(section, f){
      if(!section.containers){
        return null;
      }

      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){
            if(c.arrayDefs.onNull in this) {
              ac = this[c.arrayDefs.onNull]();
            }
            else {
              this.warn("Missing method definition for container " + c.path + ", " + c.db + ": " + c.arrayDefs.onNull );
            }
          }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        if(!container && c.objDefs && c.objDefs.onNull){
          if(c.objDefs.onNull in this) {
            container = this[c.objDefs.onNull]();
          }
          else {
            this.warn("Missing method definition for container " + c.path + ", " + c.db + ": " + c.objDefs.onNull );
          }
        }
        if(!container){
          this.setNestedObjectWithIds(this.base, this.base, c.path, {});
        }
        return this.nestedFieldValue(this.base, c.path);
      }
    },
    async getProjectStatusInfo(){
      // TODO
    },
    async getProjectData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        // this.tabs = this.isMoral ? pmTabsDefs : pfTabsDefs;
        this.client_has_bank_account_flag = response.data.client.bank_account_availability;
        this.base = response.data;
        this.isMaster = this.base.splits.length > 0;
        this.locationDefined = true;
        this.PCP = this.nestedFieldValue(this.base, "client.user.personal.credit_profile.address");
        if(this.PCP){
          this.fullLocation = this.PCP.full_address;
          this.locationDefined = true;
        }
        // if(this.PCP.property.project_id == 0){
        //   this.payload.source_address = 3;
        //   if(this.existingLocations.length == 0){
        //     this.personal.credit_profile.employer_address_id = null;
        //   }
        // } else {
        //   this.payload.source_address = 2;
        // }

        this.base.finance.guarantee = {
          guarantee_type: this.getSavedGuaranteeType(),
          ...this.base.finance.guarantee
        };

        this.legalRepresentativeIsApplicant = this.base.finance.guarantee?.guarantee_profile?.is_applicant;

        this.base.finance.owner = {
          owner_type: this.getSavedOwnerType(),
          ...this.base.finance.owner
        };
      }
      catch(e){
        console.log(e);
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfo(){
      try{
        let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pf;
        let response = await axios.get("/api/v1/forms/getFlowSectionFields/" + id);
        let url = this.ApiDomain + `/storagev3/documents/flow/${id}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;
        // console.log(response.data.sections);
        this.flow = response.data.flow;
        this.allSections = response.data.sections;
        this.allDocumentFields = [];

        await this.asyncForEach(this.allSections, async (s) => {
          if(this.tabs.filter(tt => tt.sections.includes(s.id)).length < 1){
            return;
          }

          s.documentFields = [];
          let co = 0;
          await this.asyncForEach(s.fields, async (f) => {
            this.formFieldSetter(f, this);
            if (this.isAutoFieldToSave(f)) {co += 1}

            // check for documents
            if(f.doc_id != null){

              if(s.id == 27) {
                if(f.doc_id == 31 || f.doc_id == 26) 
                  return;
              }

              let ff = {formField: f, documentSet: null, target_id: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                let dset = ss.documents.find(d => d.id == f.doc_id);
                if(dset){
                  ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                  ff.documentSet = dset;
                }
              }
              s.documentFields.push(ff);
              this.allDocumentFields.push(ff);
            }
          })

          if(co == 0){
            s.skipBtn = true;
          }
        });

        let res = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
        if(res){
          this.allDocumentFields.forEach(f => {
            if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
              this.validatedDocuments.push(f.documentSet.id);
            }
          })
        }
      }
      catch(e){
        console.log(e);
        this.warn(e);
        this.failed = true;
      }
    },
    onDocumentUploadSuccess(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'statesList', 'familyGroups', 'personalOccupationsList',
          'projectValidTerms', 'personalOccupationsList', 'businessActivities', 'banksForMexico', 'coverImagesList', 'banxicoUdis','personalBusinessActivityCategoryList'];

        let slasObjects = ['min_financed_amount_mxn', 'max_financed_amount_mxn', 'max_applicant_pf_financed_amount_udis', 'min_applicant_age', 'max_applicant_age',
          'min_guarantee_age', 'max_guarantee_age', 'max_first_project_supplier_total_cost_mxn', 'applicant_owner_less_than_age_to_automatic_guarantee_required', 'applicant_owner_more_than_age_to_automatic_guarantee_required' ];

        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        this.collections.countries = res.data.countriesList;
        this.collections.states = res.data.statesList;
        this.collections.family_groups = res.data.familyGroups;
        this.collections.personal_occupations = res.data.personalOccupationsList;
        this.collections.terms = res.data.projectValidTerms;
        this.collections.banks = res.data.banksForMexico;
        this.collections.cover_images = res.data.coverImagesList;
        this.collections.business_activities = res.data.businessActivities;
        this.collections.personal_business_activities_categories = res.data.personalBusinessActivityCategoryList;
        this.udis = res.data.banxicoUdis.udis;

        this.existingLocations = [];
        let resAddresses = await axios.get(`/api/v1/supplier/project/${this.id}/getAddressesList`);
        this.allAddresses = resAddresses.data;

        this.allAddresses.forEach(ad => {
          this.collections.existingLocations.push({id: ad.id, text: ad.full_address});
        });

        let slasParams = "with[]=" + slasObjects.join("&with[]=");
        let slasRes = await axios.get(`/api/v1/forms/getSlasValues?${slasParams}`);
        this.slas = slasRes.data;
      }
      catch (e) {
        console.log(error);
        this.warn(e);
        this.failed = true;
      }
    },
    processError(code){
      this.onError = true;
      this.errorCode = code;
    },

    /* on_change methods */
    owner_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.businessOwner);
    },

    business_owner_personal_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.ownerPersonalProfile);
    },

    guarantee_profile_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.businessGuaranteeProfile);
    },

    PF_PFAE_guarantee_profile_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.financeGuaranteeProfile);
    },

    pm_guarantee_profile_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.financeGuaranteeProfile.personal);
    },

    pm_legal_rep_profile_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.ownerPersonalProfile);
    },

    client_curp_conditions_changed(){
      this.trigger_curp_conditions_changed(this.personal);
    },

    trigger_curp_conditions_changed(obj){
      let c = obj["curp"];
      if(!c){ return; }
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);

        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          obj["birth_date"] = null;
        }
        else{
          obj["birth_date"] = date;
        }
      }
      else{
        obj["birth_date"] = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          obj["gender"] = 1;
        }
        else if(g == "M"){
          obj["gender"] = 2;
        }
        else{
          obj["gender"] = null;
        }
      }
      else{
        obj["gender"] = null;
      }
    },

    async reload_personal_cities(){
      try {
        let sid = this.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.address.city_id = null;
      } catch (e) {
        console.log(error);
        this.warn(e);
      }
    },
    async reload_business_cities(){
      try {
        let sid = this.business.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.address.city_id = null;
      } catch (e) {
        console.log(error);
        this.warn(e);
      }
    },
    async reload_personal_guarantee_cities(){
      try {
        let sid = this.personal.guarantee_profile.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.gaurantee_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.guarantee_profile.personal.address.city_id = null;
      } catch (e) {
        console.log(error);
        this.warn(e);
      }
    },
    async reload_business_owner_cities(){
      try {
        let sid = this.business.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.owner_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.personal.address.city_id = null;
      } catch (e) {
        console.log(error);
        this.warn(e);
      }
    },
    async reload_personal_credit_profile_cities(){
      try {
        let sid = this.personal.credit_profile.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.employer_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.credit_profile.address.city_id = null;
      } catch (e) {
        console.log(error);
        this.warn(e);
      }
    },

    async reload_business_guarantee_personal_cities(){
      try {
        let sid = this.business.guarantee_profile.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.guarantee_profile.personal.address.city_id = null;
      } catch (e) {
        console.log(error);
        this.warn(e);
      }
    },

    update_cp_address_source(){
      this.cpAddressSource = this.personal.credit_profile.select_address_source;
    },

    update_net_income(){
      if(this.base.finance){
        // let i1 = this.personal_credit_profile.average_income_1;
        // let i2 = this.personal_credit_profile.average_income_2;
        // let i3 = this.personal_credit_profile.average_income_3;
        let sum = 0;
        let count = 0;
        // if(i1 != null && i1 != 0){
        //   sum += parseFloat(i1);
        //   count += 1;
        // }
        // if(i2 != null && i2 != 0){
        //   sum += parseFloat(i2);
        //   count += 1;
        // }
        // if(i3 != null && i3 != 0){
        //   sum += parseFloat(i3);
        //   count += 1;
        // }

        if(count == 0){
          //this.personal_credit_profile.net_income = 0;
          this.updateNetIncome(0);
        }
        else {
          //this.personal_credit_profile.net_income = (sum / count).toFixed(2);
          this.updateNetIncome(sum / count);
        }
      }
    },

    update_personal_guarantee_country_id(){
      this.guaranteeCountryId = this.nestedFieldValue(this.base, "finance.guarantee.guarantee_profile.personal.address.country_id");
      this.should_fill_mexican_guarantee_address = this.guaranteeCountryId == this.mexicoCountryId;
      this.should_fill_foreign_guarantee_address = this.guaranteeCountryId != this.mexicoCountryId;
    },

    /**
     * TODO-DELETE
     * 04/01/2023
     */
    update_business_net_income(){
      // if(this.base.finance){
      //   let i1 = this.business_credit_profile.average_income_1;
      //   let i2 = this.business_credit_profile.average_income_2;
      //   let i3 = this.business_credit_profile.average_income_3;
      //   let sum = 0;
      //   let count = 0;
      //   if(i1 != null && i1 != 0){
      //     sum += parseFloat(i1);
      //     count += 1;
      //   }
      //   if(i2 != null && i2 != 0){
      //     sum += parseFloat(i2);
      //     count += 1;
      //   }
      //   if(i3 != null && i3 != 0){
      //     sum += parseFloat(i3);
      //     count += 1;
      //   }
      //   if(count == 0){
      //     this.updateNetIncome(0);
      //     //this.business_credit_profile.net_income = 0;
      //   }
      //   else {
      //     //this.business_credit_profile.net_income = (sum / count).toFixed(2);
      //     this.updateNetIncome(sum / count);
      //   }
      // }
    },


    /* funciones de secciones */
    newBankAccount(){
      return {id: null};
    },
    addPersonalAddress(){
      this.client.user.personal.address = {id: null};
    },

    /* validaciones especiales */
    validate_client_age_legal(){
      let years = this.calculateAgeFromDate(this.personal.birth_date);
      let min = parseInt(this.slas.min_applicant_age);
      let max = parseInt(this.slas.max_applicant_age);
      return years >= min && years <= max;
    },
    client_birthdate_invalid(){
      return `El solicitante debe tener entre ${this.slas.min_applicant_age} y ${this.slas.max_applicant_age} años`;
    },

    /*validate_owner_birthdate(){
      let years = this.calculateAgeFromDate(this.businessOwner.birth_date);
      let min = parseInt(this.slas.applicant_owner_less_than_age_to_automatic_guarantee_required);
      let max = parseInt(this.slas.applicant_owner_more_than_age_to_automatic_guarantee_required);
      return years >= min && years <= max;
    },
    owner_birthdate_invalid(){
      return `El representante legal o apoderado debe tener entre ${this.slas.applicant_owner_less_than_age_to_automatic_guarantee_required} y ${this.slas.applicant_owner_more_than_age_to_automatic_guarantee_required} años`;
    },*/

    validate_guarantee_age(){
      let years = this.calculateAgeFromDate(this.financeGuaranteeProfile.birth_date);
      let min = parseInt(this.slas.min_guarantee_age);
      let max = parseInt(this.slas.max_guarantee_age);
      return years >= min && years <= max;
    },
    validate_pm_guarantee_age(){
      let years = this.calculateAgeFromDate(this.financeGuaranteeProfile.personal.birth_date);
      let min = parseInt(this.slas.min_guarantee_age);
      let max = parseInt(this.slas.max_guarantee_age);
      return years >= min && years <= max;
    },
    validate_pm_legal_rep_guarantee_age(){
      let years = this.calculateAgeFromDate(this.ownerPersonalProfile.birth_date);
      let min = parseInt(this.slas.min_guarantee_age);
      let max = parseInt(this.slas.max_guarantee_age);
      return years >= min && years <= max;
    },
    guarantee_birthdate_invalid(){
      return `El obligado solidario debe tener entre ${this.slas.min_guarantee_age} y ${this.slas.max_guarantee_age} años`;
    },

    validate_guarantee_profile_age(){
      let years = this.calculateAgeFromDate(this.businessGuaranteeProfile.birth_date);
      let min = parseInt(this.slas.min_guarantee_age);
      let max = parseInt(this.slas.max_guarantee_age);
      return years >= min && years <= max;
    },

    guarantee_profile_birthdate_invalid(){
      return `El obligado solidario debe tener entre ${this.slas.min_guarantee_age} y ${this.slas.max_guarantee_age} años`;
    },

    async validate_client_unique_curp(){
      return await this.validate_unique_curp(this.personal.curp, this.personal.id)
    },
    async validate_guarantee_profile_unique_curp(){
      return await this.validate_unique_curp(this.businessGuaranteeProfile.curp, this.businessGuaranteeProfile.id)
    },
    async pf_validate_guarantee_profile_unique_curp(){
      return await this.validate_unique_curp(this.financeGuaranteeProfile.curp, this.financeGuaranteeProfile.id);
    },
    async pf_validate_moral_guarantee_profile_unique_curp(){
      return await this.validate_unique_curp(this.financeGuaranteeProfile.personal.curp, this.financeGuaranteeProfile.personal.id);
    },
    async pm_validate_moral_legal_rep_profile_unique_curp(){
      if (this.is_mexican_owner) {
        return await this.validate_unique_curp(this.ownerPersonalProfile.curp, this.ownerPersonalProfile.id);
      } else {
        return true;
      }
    },
    async validate_owner_clabe_is_valid(){
      if(this.client_has_bank_account){
        return this.validate_clabe(this.business.personal.bank_accounts[0].clabe, this.collections.banks);
      } else {
        return true;
      }
    },
    async validate_owner_unique_curp(){
      return await this.validate_unique_curp(this.businessOwner.curp, this.businessOwner.id)
    },
    async validate_business_owner_personal_unique_curp(){
      return await this.validate_unique_curp(this.ownerPersonalProfile.curp, this.ownerPersonalProfile.id);
    },
    async validate_client_clabe_is_valid(){
      if(this.client_has_bank_account){
        return this.validate_clabe(this.fiscal.bank_accounts[0].clabe, this.collections.banks);
      } else {
        return true;
      }
      /*if (this.isMoral){
      }
      else{
        return this.validate_clabe(this.personal.bank_accounts[0].clabe, this.collections.banks);
      }*/
    },
    async validate_client_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.personal.rfc, this.personal.id)
    },
    async validate_business_unique_rfc(){
      return await this.validate_unique_business_rfc(this.business.rfc, this.business.id)
    },
    async validate_owner_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.businessOwner.rfc, this.businessOwner.id)
    },
    async validate_business_owner_personal_unique_rfc(){
      return this.validate_unique_personal_rfc(this.ownerPersonalProfile.rfc, this.ownerPersonalProfile.id);
    },
    async validate_business_owner_business_unique_rfc(){
      if (this.should_fill_pm_rfc) {
        return this.validate_unique_business_rfc(this.ownerBusinessProfile.rfc, this.ownerBusinessProfile.id);
      }
      return true;
    },
    async validate_pm_guarantee_unique_rfc(){
      return await this.validate_unique_business_rfc(this.financeGuaranteeProfile.rfc, this.financeGuaranteeProfile.id)
    },
    async validate_pf_guarantee_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.financeGuaranteeProfile.rfc, this.financeGuaranteeProfile.id)
    },
    async validate_pm_guarantee_personal_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.financeGuaranteeProfile.personal.rfc, this.financeGuaranteeProfile.personal.id)
    },
    async validate_pm_legal_rep_unique_rfc(){
      if (this.is_mexican_owner) { 
        return await this.validate_unique_personal_rfc(this.ownerPersonalProfile.rfc, this.ownerPersonalProfile.id)
      } else {
        return [];
      }
    },
    async validate_guarantee_profile_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.businessGuaranteeProfile.rfc, this.businessGuaranteeProfile.id)
    },
    async validate_project_finances(){
      let cost = this.base.finance.total_cost;
      let count = 0;
      let milis = 15000;
      try {
        let res = await axios.get(`/api/v1/supplier/${this.base.supplier_id}/getSupplierFinishedProjectsCount`);
        count = res.data;
      } catch (error) {
        console.log(error);
        this.warn(error);
      }

      if(count < 1 && cost > parseInt(this.slas.max_first_project_supplier_total_cost_mxn) ){
        let pfsla = this.mxnFormat(this.slas.max_first_project_supplier_total_cost_mxn);
        this.errorNotif("Costo del proyecto", "El costo máximo a financiar para instaladores sin proyectos finalizados es de " + pfsla, milis);
        return false;
      }

      if(cost < parseInt(this.slas.min_financed_amount_mxn)){
        let minf = this.mxnFormat(this.slas.min_financed_amount_mxn);
        this.errorNotif("Costo del proyecto", "El costo mínimo del proyecto es de " + minf, milis);
        return false;
      }

      if(!this.isMoral){
        let maxudis = parseInt(this.slas.max_applicant_pf_financed_amount_udis);
        let fixudis = parseFloat(this.udis).toFixed(7);
        let maxpfmxn = maxudis * fixudis;

        if(this.is_pf_and_tax_profile_one && cost > parseInt(this.slas.max_financed_amount_mxn)){
          let maxf = this.mxnFormat(this.slas.max_financed_amount_mxn);
          this.errorNotif("Costo del proyecto",
            "El costo máximo a financiar para personas físicas con actividad empresarial es de " + maxf, milis);
          return false;
        } else if(!this.is_pf_and_tax_profile_one && cost > maxpfmxn){
          this.errorNotif("Costo del proyecto",
            "El costo máximo a financiar para personas físicas es de " + this.format_price(maxudis, 0) +
            " UDIS, que corresponde a " + this.mxnFormat(maxpfmxn), milis);
          return false;
        }
      }

      if(this.isMoral && cost > parseInt(this.slas.max_financed_amount_mxn)){
        let maxm = this.mxnFormat(this.slas.max_financed_amount_mxn);
        this.errorNotif("Costo del proyecto",
          "El costo máximo a financiar para personas morales es de " + maxm, milis);
        return false;
      }
      return true;
    },
    async validate_applicant_references(){
      try {
        let params = "?with[]=references";
        let response = await axios.get(`/api/v1/applicant/${this.currentClientId}${params}`);
        return (response.data.references.length >= 2);
      }
      catch (e) {
        console.log(error);
        this.warn(e);
        this.showLoading(false);
      }
      return false;
    },
    async validate_project_technologies(){
      try {
        const res = await axios.get(`/api/v1/project/${this.id}/verifyProjectTechnologies`);
        this.technologiesValidation = res.data;
        let allComplete = true;
        if(res.data.count < 1){
          return false;
        }

        this.technologiesValidation.validations.forEach(v => {
          const docsComplete = v.docValidations.complete;
          const dataComplete = v.dataValidations.complete;
          if(!docsComplete || !dataComplete){
            allComplete = false;
          }
        });
        return allComplete;
      }
      catch (e) {
        console.log(error);
        this.warn(e);
        this.showLoading(false);
      }
      return false;
    },

    async validate_project_technologies_error(){
      if(!this.technologiesValidation){
        return "Información pendiente";
      }

      if(this.technologiesValidation.count < 1){
        return "Debe agregar al menos 1 tecnología para el proyecto."
      }
      else {
        let mssg = "";
        this.technologiesValidation.validations.forEach(v => {
          if(!v.dataValidations.complete && !v.docValidations.complete){
            mssg = 'Existen tecnologías con datos y documentos pendientes de cargar';
          }
          else if(!v.docValidations.complete){
            mssg = 'Existen tecnologías con documentos pendientes de cargar';
          }
          else {
            mssg = 'Existen tecnologías con datos pendientes de cargar';
          }
        });
        return mssg;
      }
    },

    async validate_pp_income_sources(){
      let profileId = null;

      switch(this.currentGroup.slug) {
        case "income_sources":
          profileId = this.personal.id;
          this.role = "pf_income_source";
          break;
        case "pm_income_sources":
          profileId = this.business.id;
          this.role = "pm_income_source";
          break;
        case "obligated_income_sources":
          if(!this.financeGuaranteeProfile)
            return true;

          profileId = this.financeGuaranteeProfile.id;
          if(this.guaranteeType.value == 1) {
            this.role = "pf_income_source_obligated";
            break;
          }
          this.role = "pm_income_source_obligated";
          break;
        case "owner_income_sources":
          if(this.ownerType.value == 1) {
            this.role = "pf_income_source_owner";
            profileId = this.ownerPersonalProfile.id;
            break;
          }
          this.role = "pm_income_source_owner";
          profileId = this.ownerBusinessProfile.id;
          break;
        case "representative_income_sources":
          this.role = "pf_income_source_representative";
          profileId = this.business.personal.id;
          break;
      }
      return this.validateIncomeSources(profileId);
    }, 

    async validate_pp_income_sources_error(){
      if(this.incomeSourceValidation.count < 1) {
        return "Se debe dar de alta por lo menos una fuente de ingresos."
      }
      return "La información sobre la fuente de ingresos debe estar completa para enviar la solicitud a revisión.";
    },

    isObligatedSection(section_id, isOptional = false){
      if(!isOptional){
        return this.obligatedSections.some(section => section == section_id);
      } else {
        if(this.isMoral){
          return this.verifyPMOSSections.some(section => section == section_id);
        } else {
          return this.verifyPFOSSections.some(section => section == section_id);
        }
      }
    },
    verify_obligated_section(section_id){
      if(this.isMoral){
        return this.verifyPFOSSections.some(section => section == section_id);
      } else {
        return this.verifyPMOSSections.some(section => section == section_id);
      }
    },
    async requestToUpdateObligatedStatus(isRejected = 0){
      if(this.already_obligated_exist) {
        if(isRejected == 1){
          let ok = await this.checkForOSVerification();
          if(!ok){
            return;
          }
        }
        let action = "";
        switch(isRejected){
          case 0: action = "RECHAZAR"; break;
          case 1: action = "VERIFICAR"; break;
          case 2: action = "DESVERIFICAR"; break;
        }
        this.isRejected = isRejected;
        let obligated = this.get_obligated_data;
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title:  "Acción de Obligado Solidario",
          text: `¿Estás seguro de <<${action}>> al obligado solidario con email <<${obligated.email}>>?`,
          acceptText: "Confirmar",
          cancelText: 'Cancelar',
          accept: this.doRequestToUpdateObligatedStatus
        });
      } else {
        this.missingFieldsNotif("Información faltante o incorrecta", "No se ha dado de alta a un obligado solidario aún.")
      }
    },
    async doRequestToUpdateObligatedStatus(){
      try {
        this.showLoading(true);
        let obligated = this.get_obligated_data;
        let payload = {
          isRejected: this.isRejected
          , form_project_id: this.currentProjectId
          , guarantee_id: obligated.id
          , guarantee_name: `${obligated.personal.first_name} ${obligated.personal.last_name_1}`,
          guarantee_type: this.guaranteeType.value
        };

        // actualizar guarantee_profile.
        await axios.post(`/api/project/updateGuarantee`, payload);

        this.isRejected = 0;
        // solicitar la informacion actualizada del modelo
        await this.reloadFormInfo();

        this.saveSuccessNotif();
      } catch (error) {
        console.log(error);
        this.failedOperationNotif(null, null);
      } finally {
        this.showLoading(false);
      }
    },
    cleanSectionForm(tab_slug){
      let sections = this.getSectionsTab(tab_slug);
      sections.forEach(section => {
        section.containers.forEach(cc =>{
          let fields = section.data.fields;
          fields.forEach(f => {
            if(f.fname != "id"){
              cc.container[f.fname] = "";
            }
          });
        });
      });
    },
    getSectionsTab(slug){
      let tab = this.tabs.find(x => x.slug == slug);
      let sections = [];
      tab.sections.forEach(ss => {
        let s = this.allSections.find(f => f.id == ss);
        if(s != null){
          let sb = { data: s, containers: this.getContainersForSection(s.id), failed: 0 };
          sections.push(sb);
        }
        else {
          this.warn("Section " + ss + " not found");
        }
      });
      return sections;
    },
    async validateOSClean(){
      let sections = this.getSectionsTab(this.obligated_slug);
      let e = [];
      sections.forEach( async (s) => {
        let r =  await this.runValidations(s);
        if(!r) {
          this.missingFieldsNotif(); 
          e.push(r);
        }
      });
      await this.sleep(7000);
      if(e.some(x => x == false)){
        return false;
      }
      return sections;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async SaveFullOS(sections){
      this.showLoading(true);
      sections.forEach(section => {
        let base = this.collectFormData(section);
        const base_clon = Object.assign({}, base);
        if(!this.objectIsEmpty(base)){
          try {
            base_clon.id = this.base.id;
            axios.put(`/api/v2/projects/${this.id}/deepUpdate`, base_clon);
          }
          catch (error) {
            console.log(error);
            this.showLoading(false);
            this.failedOperationNotif(null, null);
            return;
          }
        }
      });
      await this.sleep(7000);
      this.showLoading(false);
      this.saveSuccessNotif();
      await this.reloadFormInfo();
      this.runSilentValidation();
    },
    show_income_sources_sections(section){
      if(section.data.id == 131){
        return !this.client_has_bank_account;
      } else {
        return true;
      }
    },
    get_income_source_role(activeTab){
      let tab = this.getSectionGroups()[activeTab];
      switch(tab.slug){
        case "income_sources": 
            this.role = "pf_income_source";
          break;
        case "obligated_income_sources": 
            if(this.guaranteeType.value == 1)
              this.role = "pf_income_source_obligated";
            else if(this.guaranteeType.value == 2)
              this.role = "pm_income_source_obligated";
            else 
              this.role = 'obligated_missing';
          break;
        case "owner_income_sources": 
          if(this.ownerType.value == 1) {
            this.role = "pf_income_source_owner";
            break;
          }
          this.role = "pm_income_source_owner";
          break;
        case "representative_income_sources":
          this.role = "pf_income_source_representative";
          break;
        case "pm_income_sources":
          this.role = "pm_income_source";
          break;
        default: 
          return this.role;
      }
      return this.role;
    },
    get_section_name(section){
      if(section.data.id == 131){
        if(this.role == "pf_income_source"){
          return section.data.public_name;  
        } else {
          return "Ingresos de obligado solidario";
        }
      } else {
        return section.data.public_name;
      }
    },
    async validateIncomeSources(personal_profile_id){
      let resValidations = await axios.get(`/api/v1/income_sources/${personal_profile_id}/${this.role}/verify`)
      this.incomeSourceValidation = resValidations.data;
      let ln = this.incomeSourceValidation.count;

      if(!this.incomeSourceValidation){
        return false;
      }

      if(ln < 1){
        return false;
      }
      let flag = true;
      let i = 0;
      
      for (i; i < ln; i++) {
        let validation = this.incomeSourceValidation.validations[i];
        if(validation.employeeDataValidations && !validation.employeeDataValidations.complete){
          flag = false;
          break;
        } else {
          let proofsComplete = validation.proofsDataValidations.findIndex(item => item.completeProof == false);
          if(proofsComplete != -1){
            flag = false;
            break;
          }
        }
      }
      return flag;
    },
    verify_zip_code_application_address() {
      this.verifyZipCode(this.personal.address, {
        zipCodeVariable: 'zip_code_application_address', 
        statesArray: 'states_application_address', 
        citiesArray: 'cities_application_address', 
        neighborhoodsArray: 'neighborhoods_application_address'
      });
    },
    set_zip_code_values_application_address() {
      this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
        zipCodeVariable: 'zip_code_application_address',
        statesArray: 'states_application_address',
        citiesArray: 'cities_application_address',
        neighborhoodsArray: 'neighborhoods_application_address'
      });
    },

    verify_zip_code_guarantee_profile_address() {
      this.verifyZipCode(this.financeGuaranteeProfile.address, {
        zipCodeVariable: 'zip_code_guarantee_profile_address', 
        statesArray: 'states_guarantee_profile_address', 
        citiesArray: 'cities_guarantee_profile_address', 
        neighborhoodsArray: 'neighborhoods_guarantee_profile_address'
      });
    },
    set_zip_code_values_guarantee_profile_address() {
      this.guaranteeCountryId = this.nestedFieldValue(this.base, "finance.guarantee.guarantee_profile.personal.address.country_id");
      this.should_fill_mexican_guarantee_address = this.guaranteeCountryId == this.mexicoCountryId;
      this.should_fill_foreign_guarantee_address = this.guaranteeCountryId != this.mexicoCountryId;

      this.setZipCodeAddressValues(this.financeGuaranteeProfile.address_id, this.financeGuaranteeProfile.address, {
        zipCodeVariable: 'zip_code_guarantee_profile_address',
        statesArray: 'states_guarantee_profile_address',
        citiesArray: 'cities_guarantee_profile_address',
        neighborhoodsArray: 'neighborhoods_guarantee_profile_address'
      });
    },

    verify_zip_code_owner_business_profile_address() {
      this.verifyZipCode(this.ownerBusinessProfile.address, {
        zipCodeVariable: 'zip_code_owner_business_profile_address', 
        statesArray: 'states_guarantee_profile_address', 
        citiesArray: 'cities_guarantee_profile_address', 
        neighborhoodsArray: 'neighborhoods_guarantee_profile_address'
      });
    },
    set_zip_code_values_owner_business_profile_address() {
      this.setZipCodeAddressValues(this.ownerBusinessProfile.address_id, this.ownerBusinessProfile.address, {
        zipCodeVariable: 'zip_code_owner_business_profile_address',
        statesArray: 'states_guarantee_profile_address',
        citiesArray: 'cities_guarantee_profile_address',
        neighborhoodsArray: 'neighborhoods_guarantee_profile_address'
      });
    },

    verify_zip_code_application_business_address() {
      this.verifyZipCode(this.business.address, {
        zipCodeVariable: 'zip_code_application_business_address', 
        statesArray: 'states_application_business_address', 
        citiesArray: 'cities_application_business_address', 
        neighborhoodsArray: 'neighborhoods_application_business_address'
      });
    },
    set_zip_code_values_application_business_address() {
      this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
        zipCodeVariable: 'zip_code_application_business_address',
        statesArray: 'states_application_business_address',
        citiesArray: 'cities_application_business_address',
        neighborhoodsArray: 'neighborhoods_application_business_address'
      });
    },

    verify_zip_code_business_guarantee_profile_address() {
      this.verifyZipCode(this.business.guarantee_profile.personal.address, {
        zipCodeVariable: 'zip_code_business_guarantee_profile_address', 
        statesArray: 'states_business_guarantee_profile_address', 
        citiesArray: 'cities_business_guarantee_profile_address', 
        neighborhoodsArray: 'neighborhoods_business_guarantee_profile_address'
      });
    },
    set_zip_code_values_business_guarantee_profile_address() {
      this.setZipCodeAddressValues(this.business.guarantee_profile.personal.address_id, this.business.guarantee_profile.personal.address, {
        zipCodeVariable: 'zip_code_business_guarantee_profile_address',
        statesArray: 'states_business_guarantee_profile_address',
        citiesArray: 'cities_business_guarantee_profile_address',
        neighborhoodsArray: 'neighborhoods_business_guarantee_profile_address'
      });
    },

    verify_zip_code_business_owner_address() {
      this.verifyZipCode(this.businessOwner.address, {
        zipCodeVariable: 'zip_code_business_owner_address', 
        statesArray: 'states_business_owner_address', 
        citiesArray: 'cities_business_owner_address', 
        neighborhoodsArray: 'neighborhoods_business_owner_address'
      });
    },
    set_zip_code_values_business_owner_address() {
      this.setZipCodeAddressValues(this.businessOwner.address_id, this.businessOwner.address, {
        zipCodeVariable: 'zip_code_business_owner_address',
        statesArray: 'states_business_owner_address',
        citiesArray: 'cities_business_owner_address',
        neighborhoodsArray: 'neighborhoods_business_owner_address'
      });
    },

    verify_zip_code_business_owner_personal_address() {
      this.verifyZipCode(this.ownerPersonalProfile.address, {
        zipCodeVariable: 'zip_code_business_owner_address', 
        statesArray: 'states_business_owner_address', 
        citiesArray: 'cities_business_owner_address', 
        neighborhoodsArray: 'neighborhoods_business_owner_address'
      });
    },
    set_zip_code_values_business_owner_personal_address() {
      this.setZipCodeAddressValues(this.ownerPersonalProfile.address_id, this.ownerPersonalProfile.address, {
        zipCodeVariable: 'zip_code_business_owner_address',
        statesArray: 'states_business_owner_address',
        citiesArray: 'cities_business_owner_address',
        neighborhoodsArray: 'neighborhoods_business_owner_address'
      });
    },
    async validate_is_guarantee_email_equal_to_applicants() {
      return !await this.isEmailDuplicatedInProject(this.base.finance.guarantee.guarantee_profile.email, this.id, {
        tableName: 'guarantee_profiles',
        key: 'id',
        value: this.base.finance.guarantee.guarantee_profile.id
      });
    },
    set_personal_business_activities_category() {
      if (this.personal.personal_business_activity_id == null || this.personal.personal_business_activity_id == "") {
        return;
      }
      let match = null;
      let categorySelected = null;
      for (const category of this.collections.personal_business_activities_categories) {
        match = category.activities.find(x => x.id === this.personal.personal_business_activity_id);
        if (match != undefined) {
          categorySelected = category;
          break;
        }
      }
      this.collections.personal_business_activities = categorySelected.activities;
      this.personal.personal_business_activity_category_id = categorySelected.id;
    },
    set_personal_business_activities() {
      if (this.personal.personal_business_activity_category_id == null || this.personal.personal_business_activity_category_id == "") {
        return;
      }
      let categorySelected = this.collections.personal_business_activities_categories.find(x => x.id === this.personal.personal_business_activity_category_id);
      this.collections.personal_business_activities = categorySelected.activities;
      this.personal.personal_business_activity_id = null;
    },
    set_os_personal_business_activities_category() {
      if (this.pf_guarantee_personal.personal_business_activity_id == null || this.pf_guarantee_personal.personal_business_activity_id == "") {
        return;
      }
      let match = null;
      let categorySelected = null;
      for (const category of this.collections.personal_business_activities_categories) {
        match = category.activities.find(x => x.id === this.pf_guarantee_personal.personal_business_activity_id);
        if (match != undefined) {
          categorySelected = category;
          break;
        }
      }
      this.collections.os_personal_business_activities = categorySelected.activities;
      this.pf_guarantee_personal.personal_business_activity_category_id = categorySelected.id;
    },
    set_os_personal_business_activities() {
      if (this.pf_guarantee_personal.personal_business_activity_category_id == null || this.pf_guarantee_personal.personal_business_activity_category_id == "") {
        return;
      }
      let categorySelected = this.collections.personal_business_activities_categories.find(x => x.id === this.pf_guarantee_personal.personal_business_activity_category_id);
      this.collections.os_personal_business_activities = categorySelected.activities;
      this.pf_guarantee_personal.personal_business_activity_id = null;
    },
    set_project_financed_interests_percentage() {
      if (this.project_finances && this.project_finances.financed_interests_percentage == null) {
        this.project_finances.financed_interests_percentage = 0;
        return;
      }
      let option = 0;
      switch(this.project_finances.financed_interests_percentage.toString()) {
        case "0.25": option = 1; break;
        case "0.50": option = 2; break;
        case "0.75": option = 3; break;
        default: option = 0; 
      }
      this.project_finances.financed_interests_percentage = option;

    },
    get_project_financed_interests_percentage(payload) {
      if (this.project_finances && this.project_finances.financed_interests_percentage == null) {
        this.project_finances.financed_interests_percentage = 0;
        return;
      }

      if (this.project_finances.financed_interests_percentage > 0) {
        let option = 0;
        switch(this.project_finances.financed_interests_percentage.toString()) {
          case "1": option = "0.25"; break;
          case "2": option = "0.50"; break;
          case "3": option = "0.75"; break;
          default: option = 0; 
        }
        this.project_finances.financed_interests_percentage = option;
        if(payload.finance){
          payload.finance.id = this.project_finances.id;
          payload.finance.financed_interests_percentage = this.project_finances.financed_interests_percentage;
        }
      }
    },
    async validate_client_unique_phone() {
      return true; //ddimas
      if (this.requestTypeSelected == 0) {
        try {
          const payload = {
            phone: this.payload.client$phone
          };
  
          const res = await axios.post(`/api/v1/forms/validate-unique-phone`, payload);
          this.uniquePhoneResponse = res.data;
          let flag = !this.uniquePhoneResponse.phone_exists; 
  
          if (!flag && this.uniquePhoneResponse.user_id == this.current_user_id) {
            flag = true;
          }
          return flag;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
      return true;
    },
    validate_client_unique_phone_error() {
      const roles = this.uniquePhoneResponse.roles;
      let txt = "Parece que el teléfono que proporcionaste ya está registrado, por favor ponte en contacto con nosotros a través del Chat para revisar tu caso."
      for (let index = 0; index < roles.length; index++) {
        const element = roles[index];
        
        if(element == "client") {
          txt = "Este teléfono ya pertenece a otro Solicitante, por favor ponte en contacto con nosotros a través del Chat para revisar tu caso.";
        } 
        
        if(element == "supplier" || element == "supplier_agent") {
          txt = "Ya tenemos registrado este teléfono y no pertenece al Solicitante. Por favor introduce el teléfono del Solicitante.";
        }
      }

      return txt;
    },
    async validate_identity_verification(){
      return true;
    },
    async validate_identity_verification_error(){
      //todo
    },
    async goToOnboarding(){
      await this.$router.push({ name: 'SupplierOnboardingHomePage' })
    },
    showFinanceFields(dbField, sectionId) {
      if(sectionId == 47) {
        if( dbField == "total_cost" ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
}
</script>

<style>

#profile-tabs li.vs-tabs--li button[color="success"] {
  color: #28DE18 !important;
}

#profile-tabs li.vs-tabs--li button[color="danger"] {
  color: rgb(234,84,85) !important;
}

.applicant-card {
  border: 1.8px solid #28de18;
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 10px;
}
  .applicant-card p {
    font-weight: bold;
    line-height: 1.8rem;
  }

  .applicant-card span {
    font-weight: normal;
  }

</style>